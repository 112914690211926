import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import HomePage from './component/homepage'
import CodePage from './component/adminPage/codepage'
import AdminPage from './component/adminPage/adminpage'

class Routes extends React.Component {
    render() {
        return (
            <Router>
                <Switch>
                    <Route exact path="/" component={HomePage} />
                    <Route exact path="/code" component={CodePage} />
                    <Route exact path="/admin" component={AdminPage} />
                </Switch>
            </Router>
        )
    }
}

export default Routes;