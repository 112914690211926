import React, { Component, useEffect, useState, useRef } from 'react'; 
import styled from "styled-components";
import Logo from "../../img/logoWhite.png";
import db from '../../firestore';
import storage from '../../firestorage';
import Upload from "./upload.js";

// var firestore = firebase.firestore();

// const settings = {timestampsInSnapshots: true};
// firestore.settings(settings);

const AdminPage = () => {
    const isAdmin = localStorage.getItem('admin')
    // console.log(isAdmin)
    const [techinfo, setTechinfo] = useState()
    const [memberinfo, setMemberinfo] = useState()
    const [typing, setTyping] = useState(false)
    const [linkInfo, setLinkInfo] = useState()
    const [historyInfo, setHistoryInfo] = useState()
    const [mapInfo, setMapInfo] = useState();
    const [contactInfo, setContactInfo] = useState();


    useEffect(()=>{
        var docRef = db.collection('tech').doc('business')
        console.log("들어오긴함?")
        docRef.get().then((doc)=> {
            if (doc.exists) {
                setTechinfo(doc.data())
                console.log(techinfo)
            } else {
                console.log("No such document!")
            }
        }).catch((error) => {
            console.log("Error getting document:", error)
        })

        var docRef2 = db.collection('tech').doc('member')
        docRef2.get().then((doc)=> {
            if (doc.exists) {
                setMemberinfo(doc.data())
                console.log(memberinfo)
            } else {
                console.log("No such document!")
            }
        }).catch((error) => {
            console.log("Error getting document:", error)
        })

        var docRef3 = db.collection('tech').doc('link')
        docRef3.get().then((doc)=> {
            if (doc.exists) {
                setLinkInfo(doc.data())
                console.log(linkInfo)
            } else {
                console.log("No such document!")
            }
        }).catch((error) => {
            console.log("Error getting document:", error)
        })

        var docRef4 = db.collection('tech').doc('history')
        docRef4.get().then((doc)=> {
            if (doc.exists) {
                setHistoryInfo(doc.data())
                console.log(historyInfo)
            } else {
                console.log("No such document!")
            }
        }).catch((error) => {
            console.log("Error getting document:", error)
        })

        var docRef5 = db.collection('tech').doc('map')
        docRef5.get().then((doc)=> {
            if (doc.exists) {
                setMapInfo(doc.data())
                console.log(mapInfo)
            } else {
                console.log("No such document!")
            }
        }).catch((error) => {
            console.log("Error getting document:", error)
        })

        var docRef6 = db.collection('tech').doc('contact')
        docRef6.get().then((doc)=> {
            if (doc.exists) {
                setContactInfo(doc.data())
                console.log(contactInfo)
            } else {
                console.log("No such document!")
            }
        }).catch((error) => {
            console.log("Error getting document:", error)
        })

    }, [])

    useEffect(()=>{

    }, [typing])


    const setOnClick = () => {
        db.collection('tech').doc('business').set({
            data: techinfo.data
        }).then(()=>{
            console.log(techinfo);
            alert("저장 완료!");
            window.location.reload();
        })
    }

    const setMemberOnClick = () => {
        db.collection('tech').doc('member').set({
            data: memberinfo.data
        }).then(()=>{
            console.log(memberinfo);
            alert("저장 완료!");
            window.location.reload();
        })
    }

    const setLinkOnClick = () => {
        db.collection('tech').doc('link').set({
            data: linkInfo.data
        }).then(()=>{
            console.log(linkInfo);
            alert("저장 완료!");
            window.location.reload();
        })
    }

    const setContactOnClick = () => {
        db.collection('tech').doc('contact').set({
            data: contactInfo.data
        }).then(()=>{
            console.log(contactInfo);
            alert("저장 완료!");
            window.location.reload();
        })
    }

    const setMapOnClick = () => {
        db.collection('tech').doc('map').set({
            data: mapInfo.data
        }).then(()=>{
            console.log(mapInfo);
            alert("저장 완료!");
            window.location.reload();
        })
    }

    const setHistoryOnClick = () => {
        db.collection('tech').doc('history').set({
            data: historyInfo.data
        }).then(()=>{
            console.log(historyInfo);
            alert("저장 완료!");
            window.location.reload();
        })
    }

    const addOnClick = () => {
        setTyping(!typing)
        let tmpTechinfo = techinfo.data
        console.log(tmpTechinfo)
        tmpTechinfo = techinfo.data.push({
            title: '',
            address: '',
            company: '',
            grossFloorArea: '',
            buildingArea: '',
            high: '',
            low: '',
            roadNetwork: '',
            scale: '',
            year: '',
            id: techinfo.data.length + 1,
            centerImage: '',
            mapImage: ''
        })
        setTechinfo(techinfo)
    }

    const addTeamOnClick = () => {
        setTyping(!typing)
        let tmpMemberinfo = memberinfo.data
        tmpMemberinfo = memberinfo.data.push({
            name: '',
            position1: '',
            position2: '',
            experience: [
                '', '', '', '', '', '', '', '', '', ''
            ]
        })
        setMemberinfo(memberinfo)
    }

    const removeOnClick = (index) => {
        if(techinfo.data.length > 1){
            setTyping(!typing)
            let tmpTechinfo = techinfo.data
            tmpTechinfo = techinfo.data.splice(index, 1)
            setTechinfo(techinfo)
        }
    }

    const removeMemberOnClick = (index) => {
        if(memberinfo.data.length > 1){
            setTyping(!typing)
            let tmpMemberinfo = memberinfo.data
            tmpMemberinfo = memberinfo.data.splice(index, 1)
            setMemberinfo(memberinfo)
        }
    }

    const addLinkOnClick = () => {
        setTyping(!typing)
        let tmpLinkInfo = linkInfo.data
        tmpLinkInfo = linkInfo.data.push({
            title: '',
            link: ''
        })
        setLinkInfo(linkInfo)
    }

    const removeLinkOnClick = (index) => {
        if(linkInfo.data.length > 1){
            setTyping(!typing)
            let tmpLinkInfo = linkInfo.data
            tmpLinkInfo = linkInfo.data.splice(index, 1)
            setLinkInfo(linkInfo)
        }
    }

    const addMapOnClick = () => {
        setTyping(!typing)
        let tmpMapInfo = mapInfo.data
        tmpMapInfo = mapInfo.data.push({
            left: true,
            line_left: 0,
            line_top: 0,
            point_left: 0,
            point_top: 0,
            title: '센터 이름',
            title_left: 0,
            title_top: 0
        })
        setMapInfo(mapInfo)
    }

    const removeMapOnClick = (index) => {
        if(mapInfo.data.length > 1){
            setTyping(!typing)
            let tmpMapInfo = mapInfo.data
            tmpMapInfo = mapInfo.data.splice(index, 1)
            setMapInfo(mapInfo)
        }
    }

    const addHistoryOnClick = () => {
        setTyping(!typing)
        let tmpHistoryInfo = historyInfo.data
        tmpHistoryInfo = historyInfo.data.push({
            year: '',
            month: [
                {
                    eng_month: '',
                    kor_month:'',
                    event: [
                        {
                            detail: '',
                            category: ''
                        }
                    ]
                }
            ]
        })
        setHistoryInfo(historyInfo)
    }

    const removeHistoryOnClick = (index) => {
        if(historyInfo.data.length > 1){
            setTyping(!typing)
            let tmpHistoryInfo = historyInfo.data
            tmpHistoryInfo = historyInfo.data.splice(index, 1)
            setHistoryInfo(historyInfo)
        }
    }

    return (
        <PageContainer>
            <div style={{marginLeft:"10%", marginRight:"10%"}}>
                {isAdmin == 'true' ? 
                <>
                    <TitleContainer>
                        <Title>관리자</Title>
                        <Title style={{marginLeft:"10px", fontWeight:400}}>페이지</Title>
                    </TitleContainer>
                    <Line />
                    <Title style={{color:"white", fontWeight:500, marginTop:"50px", fontSize:"22px"}}>보유기술 정보 수정하기</Title>

                    <CardContainer>
                        {techinfo?<>
                            {techinfo.data.map((info, index) => (
                                <ElementCard>
                                    <ElementTitle 
                                        value={info.title} 
                                        onChange={(e)=>{
                                            var tmpData = techinfo;
                                            tmpData.data[index].title = e.target.value;
                                            setTyping(!typing)
                                            setTechinfo(tmpData)
                                        }}
                                    />
                                    <DeleteButton onClick={()=>removeOnClick(index)}>-</DeleteButton>
                                    <InfoContainer>
                                        <Upload />
                                    </InfoContainer>
                                    <InfoContainer>
                                        <InfoLeft>센터사진</InfoLeft>
                                        <InfoRight 
                                            value={info.centerImage} 
                                            onChange={(e)=>{
                                                var tmpData = techinfo;
                                                tmpData.data[index].centerImage = e.target.value;
                                                setTyping(!typing)
                                                setTechinfo(tmpData)
                                            }}
                                        />
                                    </InfoContainer>
                                    <InfoContainer>
                                        <InfoLeft>지도사진</InfoLeft>
                                        <InfoRight 
                                            value={info.mapImage} 
                                            onChange={(e)=>{
                                                var tmpData = techinfo;
                                                tmpData.data[index].mapImage = e.target.value;
                                                setTyping(!typing)
                                                setTechinfo(tmpData)
                                            }}
                                        />
                                    </InfoContainer>
                                    <InfoContainer>
                                        <InfoLeft>소재지</InfoLeft>
                                        <InfoRight 
                                            value={info.address} 
                                            onChange={(e)=>{
                                                var tmpData = techinfo;
                                                tmpData.data[index].address = e.target.value;
                                                setTyping(!typing)
                                                setTechinfo(tmpData)
                                            }}
                                        />
                                    </InfoContainer>
                                    <InfoContainer>
                                        <InfoLeft>시공사</InfoLeft>
                                        <InfoRight 
                                            value={info.company} 
                                            onChange={(e)=>{
                                                var tmpData = techinfo;
                                                tmpData.data[index].company = e.target.value;
                                                setTyping(!typing)
                                                setTechinfo(tmpData)
                                            }}
                                        />
                                    </InfoContainer>
                                    <InfoContainer>
                                        <InfoLeft>연면적</InfoLeft>
                                        <InfoRight 
                                            value={info.grossFloorArea} 
                                            onChange={(e)=>{
                                                var tmpData = techinfo;
                                                tmpData.data[index].grossFloorArea = e.target.value;
                                                setTyping(!typing)
                                                setTechinfo(tmpData)
                                            }}
                                        />
                                    </InfoContainer>
                                    <InfoContainer>
                                        <InfoLeft>건축면적</InfoLeft>
                                        <InfoRight 
                                            value={info.buildingArea} 
                                            onChange={(e)=>{
                                                var tmpData = techinfo;
                                                tmpData.data[index].buildingArea = e.target.value;
                                                setTyping(!typing)
                                                setTechinfo(tmpData)
                                            }}
                                        />
                                    </InfoContainer>
                                    <InfoContainer>
                                        <InfoLeft>상온</InfoLeft>
                                        <InfoRight 
                                            value={info.high} 
                                            onChange={(e)=>{
                                                var tmpData = techinfo;
                                                tmpData.data[index].high = e.target.value;
                                                setTyping(!typing)
                                                setTechinfo(tmpData)
                                            }}
                                        />
                                    </InfoContainer>
                                    <InfoContainer>
                                        <InfoLeft>저온</InfoLeft>
                                        <InfoRight 
                                            value={info.low} 
                                            onChange={(e)=>{
                                                var tmpData = techinfo;
                                                tmpData.data[index].low = e.target.value;
                                                setTyping(!typing)
                                                setTechinfo(tmpData)
                                            }}
                                        />
                                    </InfoContainer>
                                    <InfoContainer>
                                        <InfoLeft>도로망</InfoLeft>
                                        <InfoRight 
                                            value={info.roadNetwork} 
                                            onChange={(e)=>{
                                                var tmpData = techinfo;
                                                tmpData.data[index].roadNetwork = e.target.value;
                                                setTyping(!typing)
                                                setTechinfo(tmpData)
                                            }}
                                        />
                                    </InfoContainer>
                                    <InfoContainer>
                                        <InfoLeft>규모</InfoLeft>
                                        <InfoRight 
                                            value={info.scale} 
                                            onChange={(e)=>{
                                                var tmpData = techinfo;
                                                tmpData.data[index].scale = e.target.value;
                                                setTyping(!typing)
                                                setTechinfo(tmpData)
                                            }}
                                        />
                                    </InfoContainer>
                                    <InfoContainer>
                                        <InfoLeft>준공시기</InfoLeft>
                                        <InfoRight 
                                            value={info.year} 
                                            onChange={(e)=>{
                                                var tmpData = techinfo;
                                                tmpData.data[index].year = e.target.value;
                                                setTyping(!typing)
                                                setTechinfo(tmpData)
                                            }}
                                        />
                                    </InfoContainer>
                                </ElementCard>
                            ))}
                            <AddButton onClick={addOnClick}>
                                    <Plus>+</Plus>
                            </AddButton>
                            </>:<>
                        </>}
                    </CardContainer>
                    <div style={{width:"100%", display:"flex", justifyContent:"right", textAlign:"right"}}>
                        <SaveButton onClick={setOnClick}>저장하기</SaveButton>
                    </div>


                    <Title style={{color:"white", fontWeight:500, marginTop:"50px", fontSize:"22px"}}>메인 지도 좌표 수정하기</Title>

                    <CardContainer>
                        {mapInfo?<>
                            {mapInfo.data.map((info, index) => (
                                <ElementCard>
                                    <ElementTitle 
                                        value={info.title} 
                                        onChange={(e)=>{
                                            var tmpData = mapInfo;
                                            tmpData.data[index].title = e.target.value;
                                            setTyping(!typing)
                                            setMapInfo(tmpData)
                                        }}
                                    />
                                    <DeleteButton onClick={()=>removeMapOnClick(index)}>-</DeleteButton>
                                    <InfoContainer>
                                        <InfoLeft>점 x좌표</InfoLeft>
                                        <InfoRight 
                                            value={info.point_left} 
                                            onChange={(e)=>{
                                                var tmpData = mapInfo;
                                                tmpData.data[index].point_left = Number(e.target.value);
                                                setTyping(!typing)
                                                setMapInfo(tmpData)
                                            }}
                                        />
                                    </InfoContainer>
                                    <InfoContainer>
                                        <InfoLeft>점 y좌표</InfoLeft>
                                        <InfoRight 
                                            value={info.point_top} 
                                            onChange={(e)=>{
                                                var tmpData = mapInfo;
                                                tmpData.data[index].point_top = Number(e.target.value);
                                                setTyping(!typing)
                                                setMapInfo(tmpData)
                                            }}
                                        />
                                    </InfoContainer>
                                    <InfoContainer>
                                        <InfoLeft>선 x좌표</InfoLeft>
                                        <InfoRight 
                                            value={info.line_left} 
                                            onChange={(e)=>{
                                                var tmpData = mapInfo;
                                                tmpData.data[index].line_left = Number(e.target.value);
                                                setTyping(!typing)
                                                setMapInfo(tmpData)
                                            }}
                                        />
                                    </InfoContainer>
                                    <InfoContainer>
                                        <InfoLeft>선 y좌표</InfoLeft>
                                        <InfoRight 
                                            value={info.line_top} 
                                            onChange={(e)=>{
                                                var tmpData = mapInfo;
                                                tmpData.data[index].line_top = Number(e.target.value);
                                                setTyping(!typing)
                                                setMapInfo(tmpData)
                                            }}
                                        />
                                    </InfoContainer>
                                    <InfoContainer>
                                        <InfoLeft>이름 x좌표</InfoLeft>
                                        <InfoRight 
                                            value={info.title_left} 
                                            onChange={(e)=>{
                                                var tmpData = mapInfo;
                                                tmpData.data[index].title_left = Number(e.target.value);
                                                setTyping(!typing)
                                                setMapInfo(tmpData)
                                            }}
                                        />
                                    </InfoContainer>
                                    <InfoContainer>
                                        <InfoLeft>이름 y좌표</InfoLeft>
                                        <InfoRight 
                                            value={info.title_top} 
                                            onChange={(e)=>{
                                                var tmpData = mapInfo;
                                                tmpData.data[index].title_top = Number(e.target.value);
                                                setTyping(!typing)
                                                setMapInfo(tmpData)
                                            }}
                                        />
                                    </InfoContainer>
                                    <InfoContainer>
                                        <InfoLeft>왼쪽?</InfoLeft>
                                        <button
                                            style={{
                                                width:"250px",
                                                color:"black",
                                                fontSize:"14px"
                                            }}
                                            onClick={()=>{
                                                var tmpData = mapInfo;
                                                tmpData.data[index].left = !tmpData.data[index].left;
                                                setTyping(!typing)
                                                setMapInfo(tmpData)
                                            }}
                                        >{String(info.left)}</button>
                                    </InfoContainer>
                                    <div>
                                        방향이 왼쪽일 때, <br/>
                                        선 x좌표 = 점 x좌표 - 85<br/>
                                        선 y좌표 = 점 y좌표 + 20<br/>
                                        이름 x좌표 = 선 x좌표<br/>
                                        이름 y좌표 = 점 y좌표<br/><br/>
                                        방향이 오른쪽일 때, <br/>
                                        선 x좌표 = 점 x좌표 + 50<br/>
                                        선 y좌표 = 점 y좌표 + 20<br/>
                                        이름 x좌표 = 선 x좌표 + 20<br/>
                                        이름 y좌표 = 점 y좌표<br/><br/>
                                    </div>
                                </ElementCard>
                            ))}
                            <AddButton onClick={addMapOnClick}>
                                    <Plus>+</Plus>
                            </AddButton>
                            </>:<>
                        </>}
                    </CardContainer>
                    <div style={{width:"100%", display:"flex", justifyContent:"right", textAlign:"right"}}>
                        <SaveButton onClick={setMapOnClick}>저장하기</SaveButton>
                    </div>


                    <Title style={{color:"white", fontWeight:500, marginTop:"50px", fontSize:"22px"}}>임원소개 정보 수정하기</Title>

                    <CardContainer>
                        {memberinfo?<>
                            {memberinfo.data.map((info, index) => (
                                <ElementCard>
                                    <ElementTitle 
                                        value={info.name} 
                                        onChange={(e)=>{
                                            var tmpData = memberinfo;
                                            tmpData.data[index].name = e.target.value;
                                            setTyping(!typing)
                                            setMemberinfo(tmpData)
                                        }}
                                    />
                                    <DeleteButton onClick={()=>removeMemberOnClick(index)}>-</DeleteButton>
                                    <InfoContainer>
                                        <InfoLeft>직급1</InfoLeft>
                                        <InfoRight 
                                            value={info.position1} 
                                            onChange={(e)=>{
                                                var tmpData = memberinfo;
                                                tmpData.data[index].position1 = e.target.value;
                                                setTyping(!typing)
                                                setMemberinfo(tmpData)
                                            }}
                                        />
                                    </InfoContainer>
                                    <InfoContainer>
                                        <InfoLeft>직급2</InfoLeft>
                                        <InfoRight 
                                            value={info.position2} 
                                            onChange={(e)=>{
                                                var tmpData = memberinfo;
                                                tmpData.data[index].position2 = e.target.value;
                                                setTyping(!typing)
                                                setMemberinfo(tmpData)
                                            }}
                                        />
                                    </InfoContainer>
                                    {info.experience.map((exp, index2) => (
                                        <InfoContainer>
                                            <InfoLeft>경력{index2+ 1}</InfoLeft>
                                            <InfoRight 
                                                value={exp} 
                                                onChange={(e)=>{
                                                    var tmpData = memberinfo;
                                                    tmpData.data[index].experience[index2] = e.target.value;
                                                    setTyping(!typing)
                                                    setMemberinfo(tmpData)
                                                }}
                                            />
                                        </InfoContainer>
                                    ))}
                                </ElementCard>
                            ))}
                            <AddButton onClick={addTeamOnClick}>
                                <Plus>+</Plus>
                            </AddButton></>:<></>}
                        </CardContainer>
                        <div style={{width:"100%", display:"flex", justifyContent:"right", textAlign:"right"}}>
                            <SaveButton onClick={setMemberOnClick}>저장하기</SaveButton>
                        </div>

                        <Title style={{color:"white", fontWeight:500, marginTop:"50px", fontSize:"22px"}}>문의하기 정보 수정하기</Title>

                        <CardContainer>
                            {contactInfo?<>
                                    <ElementCard>
                                        <InfoContainer>
                                            <InfoLeft>임대 전화</InfoLeft>
                                            <InfoRight 
                                                value={contactInfo.data.rentPhone} 
                                                onChange={(e)=>{
                                                    var tmpData = contactInfo;
                                                    tmpData.data.rentPhone = e.target.value;
                                                    setTyping(!typing)
                                                    setContactInfo(tmpData)
                                                }}
                                            />
                                        </InfoContainer>
                                        <InfoContainer>
                                            <InfoLeft>임대 이메일</InfoLeft>
                                            <InfoRight 
                                                value={contactInfo.data.rentEmail} 
                                                onChange={(e)=>{
                                                    var tmpData = contactInfo;
                                                    tmpData.data.rentEmail = e.target.value;
                                                    setTyping(!typing)
                                                    setContactInfo(tmpData)
                                                }}
                                            />
                                        </InfoContainer>
                                        <InfoContainer>
                                            <InfoLeft>3PL 전화</InfoLeft>
                                            <InfoRight 
                                                value={contactInfo.data.plPhone} 
                                                onChange={(e)=>{
                                                    var tmpData = contactInfo;
                                                    tmpData.data.plPhone = e.target.value;
                                                    setTyping(!typing)
                                                    setContactInfo(tmpData)
                                                }}
                                            />
                                        </InfoContainer>
                                        <InfoContainer>
                                            <InfoLeft>3PL 이메일</InfoLeft>
                                            <InfoRight 
                                                value={contactInfo.data.plEmail} 
                                                onChange={(e)=>{
                                                    var tmpData = contactInfo;
                                                    tmpData.data.plEmail = e.target.value;
                                                    setTyping(!typing)
                                                    setContactInfo(tmpData)
                                                }}
                                            />
                                        </InfoContainer>
                                    </ElementCard></>:<></>}
                            </CardContainer>
                            <div style={{width:"100%", display:"flex", justifyContent:"right", textAlign:"right"}}>
                                <SaveButton onClick={setContactOnClick}>저장하기</SaveButton>
                            </div>


                        <Title style={{color:"white", fontWeight:500, marginTop:"50px", fontSize:"22px"}}>issue & pr 수정하기</Title>

                        <CardContainer>
                            {linkInfo?<>
                                {linkInfo.data.map((info, index) => (
                                    <ElementCard>
                                        <DeleteButton onClick={()=>removeLinkOnClick(index)}>-</DeleteButton>
                                        <InfoContainer>
                                            <InfoLeft>기사 제목</InfoLeft>
                                            <InfoRight 
                                                value={info.title} 
                                                onChange={(e)=>{
                                                    var tmpData = linkInfo;
                                                    tmpData.data[index].title = e.target.value;
                                                    setTyping(!typing)
                                                    setLinkInfo(tmpData)
                                                }}
                                            />
                                        </InfoContainer>
                                        <InfoContainer>
                                            <InfoLeft>기사 링크</InfoLeft>
                                            <InfoRight 
                                                value={info.link} 
                                                onChange={(e)=>{
                                                    var tmpData = linkInfo;
                                                    tmpData.data[index].link = e.target.value;
                                                    setTyping(!typing)
                                                    setLinkInfo(tmpData)
                                                }}
                                            />
                                        </InfoContainer>
                                    </ElementCard>
                                ))}
                                <AddButton onClick={addLinkOnClick}>
                                    <Plus>+</Plus>
                                </AddButton></>:<></>}
                            </CardContainer>
                            <div style={{width:"100%", display:"flex", justifyContent:"right", textAlign:"right"}}>
                                <SaveButton onClick={setLinkOnClick}>저장하기</SaveButton>
                            </div>



                            <Title style={{color:"white", fontWeight:500, marginTop:"50px", fontSize:"22px"}}>History 수정하기</Title>

                        <CardContainer>
                            {historyInfo?<>
                                {historyInfo.data.map((info, index) => (
                                    <ElementCard>
                                        <DeleteButton onClick={()=>removeHistoryOnClick(index)}>-</DeleteButton>
                                        <InfoContainer>
                                            <InfoLeft>연도</InfoLeft>
                                            <InfoRight 
                                                value={info.year} 
                                                onChange={(e)=>{
                                                    var tmpData = historyInfo;
                                                    tmpData.data[index].year = e.target.value;
                                                    setTyping(!typing)
                                                    setHistoryInfo(tmpData)
                                                }}
                                            />
                                        </InfoContainer>
                                        {info.month.map((month, index2) => (
                                            <>
                                                <ElementTitle
                                                    value={month.kor_month} 
                                                    onChange={(e)=>{
                                                        var tmpData = historyInfo;
                                                        tmpData.data[index].month[index2].kor_month = e.target.value;
                                                        setTyping(!typing)
                                                        setHistoryInfo(tmpData)
                                                    }}
                                                    />
                                                <InfoContainer>
                                                    <InfoLeft>영어 달</InfoLeft>
                                                    <InfoRight 
                                                        value={month.eng_month} 
                                                        onChange={(e)=>{
                                                            var tmpData = historyInfo;
                                                            tmpData.data[index].month[index2].eng_month = e.target.value;
                                                            setTyping(!typing)
                                                            setHistoryInfo(tmpData)
                                                        }}
                                                    />
                                                </InfoContainer>
                                                {month.event.map((evt, index3) => (
                                                    <>
                                                        <InfoContainer>
                                                            <InfoLeft>event {index3 + 1}</InfoLeft>
                                                            <InfoRight 
                                                                value={evt.detail} 
                                                                onChange={(e)=>{
                                                                    var tmpData = historyInfo;
                                                                    tmpData.data[index].month[index2].event[index3].detail = e.target.value;
                                                                    setTyping(!typing)
                                                                    setHistoryInfo(tmpData)
                                                                }}
                                                            />
                                                        </InfoContainer>
                                                        <InfoContainer>
                                                            <InfoLeft>category {index3 + 1}</InfoLeft>
                                                            <InfoRight 
                                                                value={evt.category} 
                                                                onChange={(e)=>{
                                                                    var tmpData = historyInfo;
                                                                    tmpData.data[index].month[index2].event[index3].category = e.target.value;
                                                                    setTyping(!typing)
                                                                    setHistoryInfo(tmpData)
                                                                }}
                                                            />
                                                        </InfoContainer>
                                                    </>
                                                ))}
                                                <AddButton
                                                    style={{width:"50px", height:"50px"}}
                                                    onClick={()=>{
                                                        setTyping(!typing)
                                                        let tmpHistoryInfo = historyInfo.data
                                                        tmpHistoryInfo = historyInfo.data[index].month[index2].event.push({
                                                            detail: '',
                                                            category: ''
                                                        })
                                                        setHistoryInfo(historyInfo)
                                                    }}>
                                                    <Plus style={{fontSize:"30px", marginTop:"-20px"}}>+</Plus>
                                                </AddButton>
                                                <AddButton
                                                    style={{width:"50px", height:"50px"}}
                                                    onClick={()=>{
                                                        setTyping(!typing)
                                                        let tmpHistoryInfo = historyInfo.data
                                                        tmpHistoryInfo = historyInfo.data[index].month[index2].event.pop()
                                                        setHistoryInfo(historyInfo)
                                                    }}>
                                                    <Plus style={{fontSize:"30px", marginTop:"-20px"}}>-</Plus>
                                                </AddButton>
                                            </>
                                        ))}
                                        <div>마지막에 월 삭제/추가</div>
                                        <AddButton
                                            style={{width:"50px", height:"50px"}}
                                            onClick={()=>{
                                                setTyping(!typing)
                                                let tmpHistoryInfo = historyInfo.data
                                                tmpHistoryInfo = historyInfo.data[index].month.push({
                                                    eng_month: '',
                                                    kor_month:'',
                                                    event: [
                                                        {
                                                            detail: '',
                                                            category: ''
                                                        }
                                                    ]
                                                })
                                                setHistoryInfo(historyInfo)
                                            }}>
                                            <Plus style={{fontSize:"30px", marginTop:"-20px"}}>+</Plus>
                                        </AddButton>
                                        <AddButton
                                            style={{width:"50px", height:"50px"}}
                                            onClick={()=>{
                                                setTyping(!typing)
                                                let tmpHistoryInfo = historyInfo.data
                                                tmpHistoryInfo = historyInfo.data[index].month.pop()
                                                setHistoryInfo(historyInfo)
                                            }}>
                                            <Plus style={{fontSize:"30px", marginTop:"-20px"}}>-</Plus>
                                        </AddButton>
                                    </ElementCard>
                                ))}
                                <AddButton onClick={addHistoryOnClick}>
                                    <Plus>+</Plus>
                                </AddButton></>:<></>}
                            </CardContainer>
                            <div style={{width:"100%", display:"flex", justifyContent:"right", textAlign:"right"}}>
                                <SaveButton onClick={setHistoryOnClick}>저장하기</SaveButton>
                            </div>
                </> : 
                <>
                    <TitleContainer>
                        <Title>페이지 접근 권한이 없습니다.</Title>
                    </TitleContainer>
                    <Line />
                </>}
            
            </div>
        </PageContainer>
    )
}

const PageContainer = styled.div`
    background-color: #29292C;
    width: 100vw;
    min-height: 100vh;
    height: autp;
    // padding: 40px;
    // padding-left: 10%;
    // padding-right: 10%;
`

const AddButton = styled.button`
    width: 400px;
    height: 400px;
    color: white;
    margin-left: 10px;
    margin-right: 10px;
    padding-top: 25px;
    // padding-left: 19px;
    // padding-right: 19px;
    margin-bottom: 30px;
    border: 2px white solid;
    background-color: transparent;
`

const Plus = styled.div`
    font-size: 200px;
    font-weight: 100;
`

const DeleteButton = styled.button`
    width: 30px;
    height: 30px;
    border-radius: 15px;
    border: 1px solid #FFFFFF;
    background-color: transparent;
    color: white;
    font-size: 20px;
    font-weight: 700;
`

const TitleContainer = styled.div`
    padding-top: 50px;
    padding-bottom: 10px;
    display: flex;
    color: white;
`

const Title = styled.div`
    font-weight: 800;
    font-size: 25px;
`

const Line = styled.div`
    height: 3px;
    width: 100%;
    background-color: white;
`

const LogoImg = styled.img`
    width: 100px;
`

const ContentContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`

const CodeInput = styled.input`
    width: 300px;
    height: 50px;
    border: 2px white solid;
    background-color: #29292C;
    color: white;
    padding-left: 20px;
    font-size: 25px;
`

const EnterButton = styled.button`
    width: auto;
    border: 0;
    padding-left: 30px;
    padding-right: 30px;
    background-color: gray;
    color: white;
    height: 50px;
    font-size: 21px;
    font-weight: 700;
`

const ErrorLog = styled.div`
    color: red;
    font-size: 15px;
`

const InfoContainer = styled.div`
    width: 100%;
    // height: 20px;
    margin-left: 19px;
    margin-right: 19px;
    display: flex;
    margin-bottom: 8px;
    color: white;
`

const InfoLeft = styled.div`
    width: 43px;
    text-align: left;
    font-size: 11px;
    font-weight: 900;
    line-height: 20px;
`

const InfoRight = styled.input`
    width: 250px;
    text-align: left;
    font-size: 11px;
    font-weight: 400;
    word-break: keep-all;
    line-height: 20px;
`

const CardContainer = styled.div`
    width: 100%;
    display: flex;
    flex-flow: wrap;
    justify-content: center;
    padding-top: 40px;
`

const ElementCard = styled.div`
    width: 400px;
    min-height: 400px;
    color: white;
    margin-left: 10px;
    margin-right: 10px;
    padding-top: 25px;
    // padding-left: 19px;
    // padding-right: 19px;
    margin-bottom: 30px;
    border: 2px white solid;
`

const ElementTitle = styled.input`
    font-size: 17px;
    font-weight: 700;
    margin-left: 19px;
    margin-right: 19px;
    height: 30px;
    width: 300px;
    margin-bottom: 20px;
`

const SaveButton = styled.button`
    font-size: 20px;
    border: 0;
    width: 150px;
    height: 40px;
    background-color: white;
    border-radius: 10px;
    font-weight: 700;
    margin-bottom: 100px;
`

export default AdminPage;