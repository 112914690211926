import storage from '../../firestorage';
import firebase from '../../firebase';
import React, { Component } from 'react'
import './Upload.css';

export default class Upload extends Component {
    constructor(){
      super();
      this.state = {
        image: null,
        progress:0,
        downloadURL: null
      }
    }
  
    handleChange = (e) =>{
      if(e.target.files[0]){
        this.setState({
        image: e.target.files[0]
      })
    }
      // console.log(e.target.files[0])
  }
  
  handleUpload = () =>{
    // console.log(this.state.image);
    let file = this.state.image;
    var storageRef = storage.ref();
    var uploadTask = storageRef.child('folder/' + file.name).put(file);
  
    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
      (snapshot) =>{
        var progress = Math.round((snapshot.bytesTransferred/snapshot.totalBytes))*100
        this.setState({progress})
      },(error) =>{
        throw error
      },() =>{
        // uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) =>{
  
        uploadTask.snapshot.ref.getDownloadURL().then((url) =>{
          this.setState({
            downloadURL: url
          })
          console.log("hello")
          console.log(url);
        })
      document.getElementById("file").value = null
     }
   ) 
  }
  
  
    render() {
      return (
        <div>
            <div className="Upload">
            <h4>upload image</h4>
            <label>
                Choose file
            <input type="file" id="file" onChange={this.handleChange} />        
            </label>
            {this.state.progress}%
            <button className="button" onClick={this.handleUpload}>Upload</button>
            </div>
            <div className="Upload">
            <img
                className="ref"
                src={this.state.downloadURL || "https://via.placeholder.com/400x300"}
                alt="Uploaded Images"
                height="300"
                width="300"
            />
            </div>
            <br />
            <div>아래 생성된 사진 주소를 복사하여 원하는 곳에 붙여넣기</div><br />
            <div>{this.state.downloadURL}</div>
        </div>
      )
    }
  }