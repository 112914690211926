import React, { Component, useEffect, useState, useRef } from 'react'; 
import styled from "styled-components";
import $ from 'jquery';
import { GiHamburgerMenu } from 'react-icons/gi';
import Accordion from '../expAccordion/accordion';
import HorizontalScroll from 'react-scroll-horizontal'
import HorizontalScroller from 'react-horizontal-scroll-container';
import BackImage from "../../img/background2.jpeg";


const FirstSection = () => {

    return(
        <BackgroundImage>
            <CatchPrise>
                {/* <div>회사</div>
                <div style={{fontWeight:700, marginLeft:"10px", marginRight:"10px"}}>캐치프라이즈</div>
                <div>적당한 것</div> */}
            </CatchPrise>
            {/* <div style={{display:"flex", justifyContent:"center", width:"100%"}}> */}
                {/* <div style={{width:"10%", height:"100%"}}> */}
                    <HorizontalScroller>
                    {/* <div style={{width:"300px", height:"570px"}}>z</div> */}
                    <div style={{display:"flex"}}>
                        <div style={{width:"calc(100%)", height:"570px", marginLeft:"1000px"}}>
                            <Accordion />
                        </div>
                        {/* <div style={{width:"300px", height:"100px"}}>z</div> */}
                    </div>
                    </HorizontalScroller>
                {/* </div> */}
            {/* </div> */}
        </BackgroundImage>
                // <><div id="pentitle" style="display: none;" data-title="Expanding Horizontal Accordion in React"></div>
                // <div id="root"></div></>
    )
}

const BackgroundImage = styled.div`
    // display: flex;
    width: 100%;
    height: 720px;
    // padding-left: 10%;
    align-items: center;
    // background-color: #F9F9FA;
    background: linear-gradient(rgba(255,255,255,0.93), rgba(255,255,255,0.93)), url(${BackImage});
    background-size: 100% 200%;
    backdrop-filter:brightness(0%);
`

const CatchPrise = styled.div`
    font-size: 30px;
    display: flex;
    justify-content: center;
    margin-top: 0px;
    padding-top: 100px;
    margin-bottom: 15px;
`


export default FirstSection;