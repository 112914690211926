import React, { Component, useEffect, useState, useRef } from "react";
import styled from "styled-components";
import Map, { GoogleApiWrapper, Marker } from "google-maps-react";
import dotenv from "dotenv";
import $ from 'jquery';
import jQuery from 'jquery';
import "./Googlemap.css";
// window.$ = $;

dotenv.config();

export class MapView extends React.Component {
  render() {
    const style = {
      maxWidth: "800px",
        // width:"100%",
      maxHeight: "350px",
      borderRadius:"20px",
    //   width:"70%",
    //   height:"30%"
    };

    // $.noConflict();
    // jQuery( document ).ready(function () {
    //     $( "#root > div > div.forthsection > div > div > div.sc-GqePz.knLWYA > div > div.zzzzz" ).dialog({ 
    //         width: 460, maxWidth: 460, minWidth: 460 
    //     });
    //   });

    return (
      <Map
        google={this.props.google}
        resetBoundsOnResize={true}
        style={style}
        initialCenter={{
          lat: 37.4807947,
          lng: 127.1208608,
        }}
        zoom={15}
        onClick={this.onMapClicked}
        className="map"
        size={{
            width: "100%",
            height: "350px"
        }}
      >
          <Marker 
            key="서울특별시 송파구 새말로 62"
            position={{
                lat: 37.4807947,
                lng: 127.1208608,
            }}
          />
      </Map>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
})(MapView);

// export default MapView;
