import React, { Component, useEffect, useState, useRef } from 'react'; 
import Main from "./main";
import Header from "./header";
import FirstSection from "./sections/firstsection";
import SecondSection from "./sections/secondsection";
import ThirdSection from "./sections/thirdsection";
import ForthSection from "./sections/forthsection";
import FifthSection from "./sections/fifthsection";
import HistorySection from "./sections/historysection";
import Footer from "./footer";
import styled from "styled-components";
import { CgArrowDown } from "react-icons/cg"

const HomePage = () => {
    return(
        <div>
            <Header color="white"/>
            <div className={'main'}>
                <Main />
            </div>
            <ScrollBox>
                <div style={{marginTop:"20px"}}>Scroll</div>
                <CgArrowDown style={{fontSize:"20px", fontWeight:100}}/>
            </ScrollBox>
            <div className={'secondsection'}>
                <SecondSection/>
            </div>
            <div className={'historysection'}>
                <HistorySection />
            </div>
            <div className={'firstsection'}>
                <FirstSection/>
            </div>
            {/* <div className={'thirdsection'}>
                <ForthSection/>
            </div> */}
            <div className={'forthsection'}>
                <FifthSection/>
            </div>
            <div>
                <MarginBox />
            </div>
            {/* <div className={'fifthsection'}>
                <FifthSection/>
            </div> */}
            <div className={'footer'}>
                <Footer />
            </div>
        </div>
    )
}

const MarginBox = styled.div`
    width: 100%;
    height: 150px;
    background-color: white;
`

const ScrollBox = styled.div`
    width: 102px;
    height: 102px;
    border-radius: 51px;
    background-color: #8B5E41;
    color: white;
    position: fixed;
    bottom: -40px;
    margin: 0 auto;
    left: 0;
    right: 0;
    z-index: 5;
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    border: 0;
`

export default HomePage;