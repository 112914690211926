import React, { Component, useEffect, useState, useRef } from 'react'; 
import styled from "styled-components";
import $ from 'jquery';
import { GiHamburgerMenu } from 'react-icons/gi';
import Slider from '@bit/akiran.react-slick.slider';
import Person1 from '../../img/person1.png';
import Person2 from '../../img/person2.png';
import Person3 from '../../img/person3.png';
import useCollapse from 'react-collapsed';
import Sidebar from "react-sidebar";

const ThirdSection = () => {
    const [isExpanded, setExpanded] = useState(false);
    const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded });
    const [isOpen, setOpen] = useState(false);
    const [clickStatus, setClickStatus] = useState(0);
    
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    const experience = [
        [
            '現 ㈜공간창조 대표이사',
            '제포실업㈜ 대표이사 / 명동 호텔M PFV AMC',
            '㈜대명엔터프라이즈 개발사업본부장',
            '말레이시아, 대명그룹 해외법인장',
            '㈜대명레저산업 해외사업팀장',
            '스카이피앤디 대표이사',
            '국세청 세무조사관'
        ]
    ]

    return(
        <div>
            <BackgroundImage>
                <Container>
                    <CatchPrise>
                        <div>회사</div>
                        <div style={{fontWeight:700, marginLeft:"10px", marginRight:"10px"}}>캐치프라이즈</div>
                        <div>적당한 것</div>
                    </CatchPrise>
                    <div className="container">
                        <link rel="stylesheet" type="text/css" charset="UTF-8" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" />
                        <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" />
                        <style>{cssstyle}</style>
                        {/* <h2> Multiple items </h2> */}
                        <Slider {...settings}>
                            <ButtonCard
                                {...getToggleProps({
                                    onClick: () => setExpanded((prevExpanded) => !prevExpanded),
                                })}>
                                <div style={{display:"flex", justifyContent:"center"}}>
                                <h9>
                                    <LeftContent src={Person1}/>
                                    <section {...getCollapseProps()}>
                                    <RightContent>
                                        <div style={{display:"flex"}}>
                                            <InfoLeft>대표이사</InfoLeft>
                                            <InfoLine />
                                            <InfoRight>CCIM</InfoRight>
                                        </div>
                                        <div style={{textAlign:"left"}}>
                                            <Name>박형빈</Name>
                                            <Experience>Experience</Experience>
                                            {experience[0].map(text => (
                                                <ExperienceContent>{text}</ExperienceContent>
                                            ))}
                                        </div>
                                    </RightContent>
                                    </section>
                                </h9>
                                </div>
                            </ButtonCard>
                            <div>
                            <div style={{display:"flex", justifyContent:"center"}}>
                                <h9>
                                    <LeftContent src={Person2}/>
                                    <RightContent>
                                        <div style={{display:"flex"}}>
                                            <InfoLeft>대표이사</InfoLeft>
                                            <InfoLine />
                                            <InfoRight>CCIM</InfoRight>
                                        </div>
                                        <Name>ㅇㅇㅇ</Name>
                                    </RightContent>
                                </h9>
                            </div>
                            </div>
                            <div>
                            <div style={{display:"flex", justifyContent:"center"}}>
                                <h9>
                                    <LeftContent src={Person3}/>
                                    <RightContent>
                                        <div style={{display:"flex"}}>
                                            <InfoLeft>본부장</InfoLeft>
                                            <InfoLine />
                                            <InfoRight></InfoRight>
                                        </div>
                                        <Name>나형준</Name>
                                    </RightContent>
                                </h9>
                            </div>
                            </div>
                        </Slider>
                    </div>
                </Container>
            </BackgroundImage>
        </div>
    )
}

const CatchPrise = styled.div`
    font-size: 30px;
    display: flex;
    justify-content: center;
    margin-top: -20px;
    margin-bottom: 15px;
`

const BackgroundImage = styled.div`
    // display: flex;
    width: 100%;
    height: 720px;
    // padding-left: 10%;
    align-items: center;
    background: #F9F9FA;
`

const Experience = styled.div`
    color: #1C2DBA;
    font-size: 16px;
    font-weight: 700;
    margin-top: 10px;
    margin-bottom: 15px;
`

const ButtonCard = styled.button`
    border: 0;
    background-color: white;
`

const Container = styled.div`
    padding-top: 150px;
`

const LeftContent = styled.img`
    width: 219px;
    min-width: 219px;
    height: 268px;
    margin-top: 101px;
    // margin-right: 100px;

    transform: rotate(0deg);
    -moz-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
`

const Name = styled.div`
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 8px;
    margin-top: 8px;
`

const RightContent = styled.div`
    max-width: 527px;
    height: 268px;
    margin-top: 45px;
    padding: 16px;
    padding-left: 100px;
    // display: flex;
`

const ExperienceContent = styled.div`
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 12px;

`

const InfoLeft = styled.div`
    width: 60px;
    font-size: 16px;
    font-weight: 400;
    text-align: left;
`

const InfoLine = styled.div`
    width: 2px;
    height: 20px;
    background-color: #888888;
    margin-right: 8px;
`

const InfoRight = styled.div`
    width: 400px;
    font-size: 16px;
    font-weight: 400;
    text-align: left;
`

const cssstyle = `
.container {
  margin: 0 auto;
  padding: 0px 40px 40px 40px;
  max-width: 1026px;
  align-items: center;
  justify-content: center;
}
h9 {
    max-width: 605px;
    min-width: 219px;
    height: 369px;
    // background-color: #F4F4F6;
    background-color: white;
    // margin-left: 140px;
    // margin-right: 140px;
    display: flex;
    filter: drop-shadow(0 0px 12px rgba(24, 39, 75, 0.12));
}
.slick-next:before, .slick-prev:before {
    color: #000;
}
`


export default ThirdSection;