import React, { Component, useEffect, useState, useRef } from "react";
import styled from "styled-components";
import LogoImg from "../img/footerlogo4.png";
import Logo2 from "../img/footerlogo3.png";

const Footer = () => {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      setWidth(newWidth);
      // console.log("updating width");
    };

    window.addEventListener("resize", updateWindowDimensions);

    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, [width]);

  if (width == 0) {
    setWidth(window.innerWidth);
  }

  return (
    <div>
      {width > 900 ? (
        <FooterContainer>
          <div
            style={{
              width: "80%",
              display: "inline-block",
              verticalAlign: "middle",
              marginTop: "70px",
              marginLeft: "15%",
            }}
          >
            <div style={{ display: "flex" }}>
              <Logo style={{ marginRight: "30px" }} src={Logo2} />
              <Logo src={LogoImg} />
              <FooterContext>
                <FooterText>
                  서울시 송파구 새말로62(문정동) 송파 푸르지오시티 248호, 249호
                </FooterText>
                <FooterText
                  style={{ color: "rgba(150, 150, 150, 1)", fontSize: "10px" }}
                >
                  ©Hive Logistics 2021 All rights reserved.
                </FooterText>
              </FooterContext>
            </div>
          </div>
        </FooterContainer>
      ) : (
        <FooterContainer>
          <div
            style={{
              width: "80%",
              display: "inline-block",
              verticalAlign: "middle",
              marginTop: "20px",
              marginLeft: "15%",
            }}
          >
            <div style={{ display: "flex", marginLeft: "20px" }}>
              <Logo style={{ marginRight: "30px" }} src={Logo2} />
              <Logo style={{ paddingTop: "5px" }} src={LogoImg} />
            </div>
            <FooterContext style={{ marginTop: "20px" }}>
              <FooterText>
                서울시 송파구 새말로62(문정동) 송파 푸르지오시티 248호, 249호
              </FooterText>
              <FooterText
                style={{ color: "rgba(150, 150, 150, 1)", fontSize: "10px" }}
              >
                ©Hive Logistics 2021 All rights reserved.
              </FooterText>
            </FooterContext>
          </div>
        </FooterContainer>
      )}
    </div>
  );
};

const FooterContainer = styled.div`
  width: 100%;
  height: 174px;
  color: black;
  // background-color: transparent;
  background-color: #f8f8fa;
  font-size: 14px;
  // padding-top: 50px;
  // padding-bottom: 50px;
  display: inline-block;
  vertical-align: middle;
  // padding-left: 15%;
`;

const Logo = styled.img`
  width: 82px;
  height: 39px;
`;

const FooterContext = styled.div`
  margin-left: 5%;
  flex-flow: wrap;
`;

const FooterText = styled.div`
  color: #8a8a8a;
  font-size: 12px;
`;

export default Footer;
