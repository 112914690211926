// import firebase from "@firebase/app";
// import * as firebase from "firebase/app";
// import 'firebase/firestore';
// import firebase from 'firebase';
// import firebase from "firebase/app";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
// import "firebase/firestore";
// import "firebase/storage";

var config = {
    apiKey: "AIzaSyCp2jAMwioqBirRBzs2_6a0oMu87S5v8P8",
    authDomain: "create-space-11612.firebaseapp.com",
    projectId: "create-space-11612",
    storageBucket: "create-space-11612.appspot.com",
    messagingSenderId: "695099473309",
    appId: "1:695099473309:web:cd43c03840e4b7c67f4374",
    measurementId: "G-BT4G189VCL"
};

firebase.initializeApp(config);

// export default firebase;
// const auth = firebase.auth();
const storage = firebase.storage();

// export { db, auth };
export default storage;
