import React, { Component, useEffect, useState, useRef } from 'react'; 
import styled from "styled-components";
import $ from 'jquery';
import { GiHamburgerMenu } from 'react-icons/gi';
import FirstImg from '../../img/card1.png';
import SecondImg from '../../img/card2.png';
import ThirdImg from '../../img/card3.png';
import ForthImg from '../../img/card4.png';
import FifthImg from '../../img/card5.png';
import db from '../../firestore';
import techMap from '../../img/techMap.png';
import Slider from '@bit/akiran.react-slick.slider';
import Rodal from 'rodal';
import "rodal/lib/rodal.css";
import { FiArrowLeft,FiArrowRight } from "react-icons/fi"

const RenderGallery = () => {}

const FirstSection = () => {
    const [mouseOver, setMouseOver] = useState([false, false, false, false, false]);
    const [hoverState, setHoverState] = useState(false);
    const [techInfo, setTechInfo] = useState()
    const [typing, setTyping] = useState(false)
    const [visible, setVisible] = useState(false);
    const [clickValue, setClickValue] = useState(-1);
    const [index, setIndex] = useState(0);
    const [width, setWidth] = useState(0);

    useEffect(() => {
        const updateWindowDimensions = () => {
        const newWidth = window.innerWidth;
        setWidth(newWidth);
        // console.log("updating width");
        };

        window.addEventListener("resize", updateWindowDimensions);

        return () => window.removeEventListener("resize", updateWindowDimensions) 

    }, [width]);

    if(width == 0){
        setWidth(window.innerWidth)
    }
    
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000
      };

    useEffect(()=>{
        var docRef = db.collection('tech').doc('business')
        console.log("들어오긴함?")
        docRef.get().then((doc)=> {
            if (doc.exists) {
                setTechInfo(doc.data().data)
                console.log(techInfo)
            } else {
                console.log("No such document!")
            }
        }).catch((error) => {
            console.log("Error getting document:", error)
        })
    }, [])

    useEffect(()=>{

    }, [typing])

    const backimg = [FirstImg, SecondImg, ThirdImg, ForthImg, FifthImg]
    const roadimg = [techMap, techMap, techMap, techMap, techMap]

    useEffect(()=>{
        console.log("hi")
    },[hoverState])

    const handlerMouseOver = (index) => {
        if (mouseOver[index] == false) {
            let tmpMouseStatus = mouseOver
            tmpMouseStatus[index] = true
            setMouseOver(tmpMouseStatus);
            setHoverState(!hoverState)
        }
      };
    
      const handlerMouseOut = (index) => {
        if (mouseOver[index] == true) {
            let tmpMouseStatus = mouseOver
            tmpMouseStatus[index] = false
            setMouseOver(tmpMouseStatus);
            setHoverState(!hoverState)
        }
      };

    return(
       <>
       {width > 900?
       <BackgroundImage style={{height:"800px", display:"flex", justifyContent:"center"}}>
           <div style={{width:"90%"}}>
           <ButtonContainer>
               {techInfo?<>
                {techInfo.map((val, index2) => (
                    <>{index2 == index ? 
                    <CenterButton 
                        style={{width:`calc(70%/${techInfo.length})`, backgroundColor:"#1850BC"}}
                        onClick={()=>(setIndex(index2))}>
                        {val.title}
                    </CenterButton>:
                    <CenterButton 
                        style={{width:`calc(70%/${techInfo.length})`}}
                        onClick={()=>(setIndex(index2))}>
                        {val.title}
                    </CenterButton>}
                    </>
                ))}</>:<></>}
           </ButtonContainer>
       <div style={{height:"520px", display:"flex", width:"100%", justifyContent:"center"}}>
           <ArrowButton
                onClick={()=>(setIndex((index+techInfo.length-1)%techInfo.length))}><FiArrowLeft /></ArrowButton>
        {techInfo?<>
       {/* <Slider {...settings}> */}
                <BackgroundImage style={{minHeight:"100px", width:"70%", height:"100%", backgroundSize: "170% 120%"}} imgurl={techInfo[index].centerImage}>
                    <CardContainer style={{position:"relative"}}>
                    <button
                            style={{
                                width:"100%",
                                height:"220px",
                                border:0,
                                backgroundColor:"transparent"
                            }}
                            onClick={()=>{
                                setVisible(true);
                                setClickValue(index)
                                setTyping(!typing);
                            }}
                            >
                        <InfoContainer style={{marginTop:"40px", display:"unset", position:"absolute", top:-20, left:-55}}>
                                <MapImage src={techInfo[index].mapImage} />
                                <ImageDescription
                                    style={{
                                        position: "relative",
                                        top: "-35px",
                                        left: "36px"
                                    }}>
                                    {techInfo[index].roadNetwork}
                                </ImageDescription>
                        </InfoContainer>
                    </button>
                    <ElementCard
                            style={{width:"100%", height:"250px", position:"absolute", top:270}}
                            backgroundImage={techInfo[index].centerImage}>
                            <div>
                                <div style={{width:"100%", height:"375px", backgroundColor:"linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5))"}}>
                                    {/* <ElementTitle>{ele}</  ElementTitle> */}
                                    <ElementTitle>{techInfo[index].title}</ElementTitle>
                                    {/* <Line /> */}
                                    <InfoContainer>
                                        <InfoLeft>소재시</InfoLeft>
                                        <InfoRight>{techInfo[index].address}</InfoRight>
                                    </InfoContainer>
                                    <InfoContainer>
                                        <InfoLeft>시공사</InfoLeft>
                                        <InfoRight>{techInfo[index].company}</InfoRight>
                                    </InfoContainer>
                                    <InfoContainer>
                                        <InfoLeft>연면적</InfoLeft>
                                        <InfoRight>{techInfo[index].grossFloorArea}</InfoRight>
                                    </InfoContainer>
                                    <InfoContainer>
                                        <InfoLeft>건축면적</InfoLeft>
                                        <InfoRight>{techInfo[index].buildingArea}</InfoRight>
                                    </InfoContainer>
                                    <InfoContainer>
                                        <InfoLeft>상  온</InfoLeft>
                                        <InfoRight>{techInfo[index].high}</InfoRight>
                                    </InfoContainer>
                                    <InfoContainer>
                                        <InfoLeft>저  온</InfoLeft>
                                        <InfoRight>{techInfo[index].low}</InfoRight>
                                    </InfoContainer>
                                    {/* <InfoContainer>
                                        <InfoLeft>도로망</InfoLeft>
                                        <InfoRight>{techInfo[index].roadNetwork}</InfoRight>
                                    </InfoContainer> */}
                                    <InfoContainer>
                                        <InfoLeft>규  모</InfoLeft>
                                        <InfoRight>{techInfo[index].scale}</InfoRight>
                                    </InfoContainer>
                                    <InfoContainer style={{marginBottom:"50px"}}>
                                        <InfoLeft>준공시기</InfoLeft>
                                        <InfoRight>{techInfo[index].year}</InfoRight>
                                    </InfoContainer>
                                    
                                </div>
                            </div>
                    </ElementCard>
                    </CardContainer>
                </BackgroundImage>
                {/* </Slider> */}
                </>
                :<></>}
                <ArrowButton
                    onClick={()=>(setIndex((index+1)%techInfo.length))}><FiArrowRight /></ArrowButton>
                </div>
                <Rodal width={850} height={750} visible={visible} onClose={() => setVisible(false)}>
                    {clickValue!=-1?
                    <div>
                        <BigImage src={techInfo[clickValue].mapImage}/>
                    </div>:<></>}
                </Rodal>
                </div>
                </BackgroundImage>:

                <BackgroundImage>
                    <ButtonContainer>
               {techInfo?<>
                {techInfo.map((val, index2) => (
                    <>{index2 == index ? 
                    <CenterButton 
                        style={{width:`calc(70%/${techInfo.length})`, backgroundColor:"#1850BC", overflow:"hidden"}}
                        onClick={()=>(setIndex(index2))}>
                        {val.title.split(' ')[1]}
                    </CenterButton>:
                    <CenterButton 
                        style={{width:`calc(70%/${techInfo.length})`, overflow:"hidden"}}
                        onClick={()=>(setIndex(index2))}>
                        {val.title.split(' ')[1]}
                    </CenterButton>}
                    </>
                ))}</>:<></>}
           </ButtonContainer>
       <div style={{height:"520px", display:"flex", width:"100%", justifyContent:"center"}}>
           <div style={{width:"30px"}}/>
           {/* <ArrowButton
                onClick={()=>(setIndex((index+techInfo.length-1)%techInfo.length))}><FiArrowLeft /></ArrowButton> */}
        {techInfo?<>
       {/* <Slider {...settings}> */}
                <BackgroundImage style={{minHeight:"100px", width:"100%", height:"100%", backgroundPosition:"center center",backgroundSize:"500% 100%"}} imgurl={techInfo[index].centerImage}>
                    <CardContainer style={{position:"relative"}}>
                    <ElementCard
                            style={{width:"100%", height:"250px", position:"absolute", top:270, backgroundSize:"100% 100%"}}
                            backgroundImage={techInfo[index].centerImage}
                            // backgroundSize="200px 100px"
                            >
                            <div>
                                <div style={{width:"100%", height:"250px", backgroundColor:"linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5))"}}>
                                    {/* <ElementTitle>{ele}</  ElementTitle> */}
                                    <ElementTitle>{techInfo[index].title}</ElementTitle>
                                    {/* <Line /> */}
                                    <InfoContainer>
                                        <InfoLeft>소재시</InfoLeft>
                                        <InfoRight>{techInfo[index].address}</InfoRight>
                                    </InfoContainer>
                                    <InfoContainer>
                                        <InfoLeft>시공사</InfoLeft>
                                        <InfoRight>{techInfo[index].company}</InfoRight>
                                    </InfoContainer>
                                    <InfoContainer>
                                        <InfoLeft>연면적</InfoLeft>
                                        <InfoRight>{techInfo[index].grossFloorArea}</InfoRight>
                                    </InfoContainer>
                                    <InfoContainer>
                                        <InfoLeft>건축면적</InfoLeft>
                                        <InfoRight>{techInfo[index].buildingArea}</InfoRight>
                                    </InfoContainer>
                                    <InfoContainer>
                                        <InfoLeft>상  온</InfoLeft>
                                        <InfoRight>{techInfo[index].high}</InfoRight>
                                    </InfoContainer>
                                    <InfoContainer>
                                        <InfoLeft>저  온</InfoLeft>
                                        <InfoRight>{techInfo[index].low}</InfoRight>
                                    </InfoContainer>
                                    {/* <InfoContainer>
                                        <InfoLeft>도로망</InfoLeft>
                                        <InfoRight>{techInfo[index].roadNetwork}</InfoRight>
                                    </InfoContainer> */}
                                    <InfoContainer>
                                        <InfoLeft>규  모</InfoLeft>
                                        <InfoRight>{techInfo[index].scale}</InfoRight>
                                    </InfoContainer>
                                    <InfoContainer style={{marginBottom:"50px"}}>
                                        <InfoLeft>준공시기</InfoLeft>
                                        <InfoRight>{techInfo[index].year}</InfoRight>
                                    </InfoContainer>
                                </div>
                            </div>
                    </ElementCard>
                    </CardContainer>
                    
                </BackgroundImage>
                {/* </Slider> */}
                </>
                :<></>}
                <div style={{width:"30px"}}/>
                {/* <ArrowButton
                    style={{width:"20px"}}
                    onClick={()=>(setIndex((index+1)%techInfo.length))}><FiArrowRight /></ArrowButton> */}
                </div>
                <Rodal width={350} height={300} visible={visible} onClose={() => setVisible(false)}>
                    {clickValue!=-1?
                    <div>
                        <BigImage src={techInfo[clickValue].mapImage}/>
                    </div>:<></>}
                </Rodal>
                {techInfo?
                <button
                        style={{
                            width:"100%",
                            height:"220px",
                            border:0,
                            backgroundColor:"transparent",
                            zIndex:2
                        }}
                        onClick={()=>{
                            setVisible(true);
                            setClickValue(index)
                            setTyping(!typing);
                        }}
                        >
                    <InfoContainer style={{display:"unset", paddingLeft:"0px"}}>
                            <MapImage src={techInfo[index].mapImage} 
                            style={{marginLeft:"0px"}}/>
                            <div style={{width:"100%", display:"flex", justifyContent:"center"}}>
                                <ImageDescription style={{marginTop:"-30px"}}>
                                    {techInfo[index].roadNetwork}
                                </ImageDescription>
                            </div>
                    </InfoContainer>
                </button>:<></>}
                </BackgroundImage>
                
                }</>
    )
}

const ArrowButton = styled.button`
    width: 80px;
    height: 100%;
    font-size: 30px;
    background-color: transparent;
    border: 0;
`

const ButtonContainer = styled.div`
    width: 100%;
    // height: 40px;
    min-height: 40px;
    display: flex;
    justify-content: center;
    // padding-top: 120px;
    margin-top: 120px;
    overflow: auto;
`

const CenterButton = styled.button`
    min-height: 100%;
    border-radius: 20px 20px 0px 0px;
    border: 0;
    background-color: #72A2EC;
    color: white;
    word-break: keep-all;
`

const BigImage = styled.img`
    width: 100%;
    height: 100%;
    margin-top: 30px;
`

const BackgroundImage = styled.div`
    // display: flex;
    width: 100%;
    min-height: 600px;
    // padding-bottom: 100px;
    // padding-left: 10%;
    align-items: center;
    background-color: white;

    background-image: url(${props => props.imgurl});
    background-size: cover;
    // background: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${props => props.imgurl});
    background-size: 170% 120%;
    background-position: center;
    background-position: -80px -10px;
    // backdrop-filter:brightness(0%);
`

const CardContainer = styled.div`
    width: 100%;
    display: flex;
    flex-flow: wrap;
    justify-content: right;
    vertical-align: center;
`

const ElementCard = styled.div`
    width: 388px;
    height: 720px;
    color: white;
    // background-color: transparent;
    // background-color: 

    background: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.9));
    // backdrop-filter:brightness(40%);
    // backdrop-filter:blur(10px) brightness(40%);
`

const ElementTitle = styled.div`
    font-size: 17px;
    font-weight: 700;
    margin-left: 35px;
    margin-right: 35px;
    margin-top: 10px;
    margin-bottom: 12px;
`

const Line = styled.div`
    height: 2px;
    width: 23px;
    background-color: white;
    margin-left: 35px;
    margin-top: 8px;
    margin-bottom: 25px;
`

const InfoContainer = styled.div`
    // width: 100%;
    // height: 20px;
    padding-left: 35px;
    // margin-right: 35px;
    display: flex;
    margin-bottom: 2px;
`

const InfoLeft = styled.div`
    width: 50px;
    text-align: left;
    font-size: 11px;
    font-weight: 900;
    line-height: 20px;
`

const InfoRight = styled.div`
    width: 180px;
    text-align: left;
    font-size: 11px;
    font-weight: 400;
    word-break: keep-all;
    line-height: 20px;
`

const MapImage = styled.img`
    width: 232px;
    border-radius: 10px;
    margin-left: 35px;
`

const ImageDescription = styled.div`
    width: 210px;
    height: 23px;
    background-color: black;
    background: linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.3));
    backdrop-filter:brightness(40%);
    color: white;
    font-size: 13px;
    font-weight: 700;
    padding-left: 20px;
    text-align: left;
    padding-top: 8px;
    // position: relative;
    // top: -35px;
    // left: 36px;
    border-radius: 0px 0px 10px 10px;
`

const cssstyle = `
.container2 {
    margin: 0 auto;
    padding: 0px 40px 40px 40px;
    width: 70%;
    height: 80%;
  }
  h3 {
    position: relative;
    //   display: flex;
  }
  .slick-next:before, .slick-prev:before {
    color: black;
}
`

export default FirstSection;