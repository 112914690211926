import React, { Component, useEffect, useState, useRef } from "react";
import styled from "styled-components";
import $ from "jquery";
import { GiHamburgerMenu } from "react-icons/gi";
import MapImage from "../../img/map.png";
import MapView from "./googlemap";
import Map2 from "../../img/map2.png";

const FirstSection = () => {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      setWidth(newWidth);
      // console.log("updating width");
    };

    window.addEventListener("resize", updateWindowDimensions);

    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, [width]);

  if (width == 0) {
    setWidth(window.innerWidth);
  }

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        backgroundColor: "white",
      }}
    >
      {width > 900 ? (
        <BackgroundImage style={{ width: "60%" }}>
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <div style={{ width: "800px" }}>
              <Title>오시는 길</Title>
              <InformationContainer>
                <InfoBox>
                  <AddressTitle>Address</AddressTitle>
                  <Content>
                    서울시 송파구 새말로62(문정동) 송파 푸르지오시티 248호,
                    249호
                  </Content>
                </InfoBox>
                {/* <InfoBox>
                                <AddressTitle>Tel</AddressTitle>
                                <Content>+82 2 000 000</Content>
                            </InfoBox> */}
              </InformationContainer>
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            {width > 900 ? (
              <MapContainer>
                <MapView style={{ width: "70%", height: "50%" }} />
              </MapContainer>
            ) : (
              <MapContainer
                style={{
                  backgroundImage: `url(${Map2})`,
                  backgroundSize: "100% 100%",
                  height: "200px",
                }}
              />
            )}
          </div>
        </BackgroundImage>
      ) : (
        <BackgroundImage style={{ width: "90%" }}>
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <div style={{ width: "800px" }}>
              <Title>오시는 길</Title>
              <InformationContainer>
                <InfoBox>
                  <AddressTitle>Address</AddressTitle>
                  <Content>
                    서울시 송파구 새말로62(문정동) 송파 푸르지오시티 248호,
                    249호
                  </Content>
                </InfoBox>
                {/* <InfoBox>
                            <AddressTitle>Tel</AddressTitle>
                            <Content>+82 2 000 000</Content>
                        </InfoBox> */}
              </InformationContainer>
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            {width > 900 ? (
              <MapContainer>
                <MapView style={{ width: "70%", height: "50%" }} />
              </MapContainer>
            ) : (
              <MapContainer
                style={{
                  backgroundImage: `url(${Map2})`,
                  backgroundSize: "100% 100%",
                  height: "200px",
                }}
              />
            )}
          </div>
        </BackgroundImage>
      )}
    </div>
  );
};

const BackgroundImage = styled.div`
  // display: flex;
  // width: 60%;
  height: 720px;
  // padding-left: 10%;
  align-items: center;
  background-color: #ffffff;
`;

const Title = styled.div`
  font-size: 30px;
  font-weight: 700;
  // padding-left: 166px;
  margin-bottom: 25px;
  padding-top: 142px;
  // width: 100%;
  text-align: left;
`;

const InformationContainer = styled.div`
  display: flex;
  // padding-left: 166px;
  // margin-right: 266px;
`;

const AddressTitle = styled.div`
  font-size: 16px;
  font-weight: 700;
  text-align: left;
  width: 100%;
  margin-bottom: 10px;
`;

const InfoBox = styled.div`
  width: 100%;
  margin-bottom: 40px;
`;

const Content = styled.div`
  font-size: 18px;
  font-weight: 400;
  color: #8a8a8a;
`;

const MapContainer = styled.div`
  // margin-left: 166px;
  // margin-right: 266px;
  // width: 100%;
  width: 800px;
  height: 350px;
  border-radius: 20px;
  border: 1px solid #8a8a8a;
`;

export default FirstSection;
