import React, { Component, useEffect, useState, useRef } from "react";
import styled from "styled-components";
import $ from "jquery";
import { GiHamburgerMenu } from "react-icons/gi";
import TechImage from "../../img/tech.jpeg";
import Info1 from "../../img/info1.png";
import Info2 from "../../img/info2.png";
import Info3 from "../../img/info3.png";
import Info4 from "../../img/info4.png";
import db from "../../firestore";

const HistorySection = () => {
  const [historyInfo, setHistoryInfo] = useState();
  const year = ["2020", "2021", "2022", "2023", "2024", "2025", "2026"];
  const backColor = ["#1850BC", "#FB8835"];
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      setWidth(newWidth);
      // console.log("updating width");
    };

    window.addEventListener("resize", updateWindowDimensions);

    return () => window.removeEventListener("resize", updateWindowDimensions) 

  }, [width]);

  if(width == 0){
    setWidth(window.innerWidth)
}

  useEffect(() => {
    var docRef = db.collection("tech").doc("history");
    console.log("들어오긴함?");
    docRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          setHistoryInfo(doc.data());
          // console.log(doc.data()['2020'])
          console.log(historyInfo);
          console.log(historyInfo[year[0]]);
        } else {
          console.log("No such document!");
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });
  }, []);

  const elementTitle = [
    "화주 친화적 비용",
    "최첨단 물류센터",
    "호텔식 편의시설",
    "3PL 제공",
  ];

  const elementDescription = [
    "물류센터 직접 개발로 화주 친화적인 이용료",
    "물류 전문인력 참여로 고효율의 구조 및 설비",
    "호텔/리조트 개발경험으로 하이퀄리티 편의시설 제공",
    "업계 최효율적 비용의 3PL 서비스 제공",
  ];

  const infoImage = [Info1, Info2, Info3, Info4];

  return (
    <div>
      <BackgroundImage>
        <ContextContainer>
          <Context style={{ marginRight: "8px" }}>고객과 파트너에게 </Context>
          <Context style={{ fontWeight: 700 }}>최고의 만족</Context>
          <Context>을 선사합니다.</Context>
        </ContextContainer>
        <div style={{ width: "100%", justifyContent: "center", display:"flex"}}>
          {historyInfo ? (
            <div style={{maxWidth:"800px", paddingLeft:"20px", paddingRight:"20px"}}>
              {historyInfo.data.map((value, index) => (
                <>
                  {value.year ? (
                    <>
                    {width>900?
                      <ElementContainer style={{display:"flex"}}>
                        <LeftYear
                          style={{ backgroundColor: `${backColor[index % 2]}`
                        }}
                        >
                          {value.year}
                        </LeftYear>
                        <RightEvent>
                          {value.month.map((month, index2) => (
                            <>
                              <RightContent>
                                <MonthContent>
                                  <MonthCircle
                                    style={{
                                      border: `3px solid ${
                                        backColor[index % 2]
                                      }`,
                                    }}
                                  />
                                  <EngMonth
                                    style={{ color: backColor[index % 2] }}
                                  >
                                    {month.eng_month}
                                  </EngMonth>
                                  <KorMonth
                                    style={{ color: backColor[index % 2] }}
                                  >
                                    {month.kor_month}
                                  </KorMonth>
                                </MonthContent>
                                {month.event.map((evt, index3) => (
                                  <EventContent>
                                    <DetailContent>{evt.detail}</DetailContent>
                                    <CategoryContent>
                                      {evt.category}
                                    </CategoryContent>
                                  </EventContent>
                                ))}
                              </RightContent>
                            </>
                          ))}
                        </RightEvent>
                      </ElementContainer>:
                      <ElementContainer>
                      <LeftYear
                        style={{ backgroundColor: `${backColor[index % 2]}`,
                        width:"60px",
                        height:"60px",
                        borderRadius:"30px",
                        fontSize:"18px",
                        }}
                      >
                        {value.year}
                      </LeftYear>
                      <RightEvent>
                        {value.month.map((month, index2) => (
                          <>
                            <RightContent>
                              <MonthContent>
                                <MonthCircle
                                  style={{
                                    border: `3px solid ${
                                      backColor[index % 2]
                                    }`,
                                  }}
                                />
                                <EngMonth
                                  style={{ color: backColor[index % 2] }}
                                >
                                  {month.eng_month}
                                </EngMonth>
                                <KorMonth
                                  style={{ color: backColor[index % 2] }}
                                >
                                  {month.kor_month}
                                </KorMonth>
                              </MonthContent>
                              {month.event.map((evt, index3) => (
                                <EventContent>
                                  <DetailContent>{evt.detail}</DetailContent>
                                  <CategoryContent>
                                    {evt.category}
                                  </CategoryContent>
                                </EventContent>
                              ))}
                            </RightContent>
                          </>
                        ))}
                      </RightEvent>
                    </ElementContainer>}</>
                  ) : (
                    <></>
                  )}
                </>
              ))}
              </div>):<></>}
            </div>
      </BackgroundImage>
    </div>
  );
};

const BackgroundImage = styled.div`
  // display: flex;
  width: 100%;
  min-height: 720px;
  padding-bottom: 100px;
  // padding-left: 10%;
  align-items: center;
  background-color: white;
  // background: linear-gradient(rgba(255,255,255,0.8), rgba(255,255,255,0.8)), url();
  background-size: 100% 200%;
  backdrop-filter: brightness(0%);
`;

const Context = styled.div`
  font-size: 30px;
  font-weight: 400;
`;

const ContextContainer = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  flex-flow: wrap;
  justify-content: center;
  // margin-top: -300px;
  margin-bottom: 90px;
  padding-top: 200px;
`;

const ElementContainer = styled.div`
  width: auto;
//   display: flex;
`;

const ElementCard = styled.div`
  width: 200px;
  align-items: center;
  text-align: center;
  margin-bottom: 30px;
  margin-left: 10px;
  margin-right: 10px;
`;

const ElementCircle = styled.div`
  width: 116px;
  height: 116px;
  margin-left: 42px;
  margin-bottom: 30px;
  border-radius: 58px;
  border: 0;
  background-color: white;
  // box-shadow: 10px 10px 10px 10px;
  filter: drop-shadow(0 0px 8px rgba(24, 39, 75, 0.12));
  filter: drop-shadow(0 0px 12px rgba(24, 39, 75, 0.12));
`;

const ElementTitle = styled.div`
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 10px;
`;

const ElementDescription = styled.div`
  width: 145px;
  font-size: 14px;
  font-weight: 500;
  word-break: keep-all;
  line-height: 23px;
`;

const LeftYear = styled.div`
  height: 90px;
  width: 90px;
  color: white;
  border-radius: 53px;
  font-size: 26px;
  font-weight: 600;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-stretch: 50%;
`;

const RightEvent = styled.div`
  margin-left: 30px;
`;

const RightContent = styled.div``;

const MonthContent = styled.div`
  display: flex;
  margin-bottom: 10px;
  margin-top: 20px;
`;

const MonthCircle = styled.div`
  width: 7px;
  height: 7px;
  border-radius: 10px;
  margin-top: 3px;
`;
const EngMonth = styled.div`
  font-weight: 700;
  font-size: 18px;
  margin-left: 5px;
`;
const KorMonth = styled.div`
  font-size: 13px;
  margin-left: 5px;
  padding-top: 4px;
`;

const EventContent = styled.div`
  display: flex;
  margin-left: 20px;
  margin-bottom: 10px;
`;

const DetailContent = styled.div`
  font-size: 16px;
  font-weight: 600;
`;

const CategoryContent = styled.div`
  font-size: 13px;
  font-weight: 400;
  margin-left: 10px;
  color: #8a8a8a;
  padding-top: 3px;
`;

export default HistorySection;
