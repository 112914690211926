import React, { Component, useEffect, useState, useRef } from 'react'; 
import styled from "styled-components";
import $ from 'jquery';
import { GiHamburgerMenu } from 'react-icons/gi';
import TechImage from '../../img/tech.jpeg';
import Info1 from "../../img/info1.png";
import Info2 from "../../img/info2.png";
import Info3 from "../../img/info3.png";
import Info4 from "../../img/info4.png";


const FirstSection = () => {
    const [width, setWidth] = useState(0);

    useEffect(() => {
        const updateWindowDimensions = () => {
        const newWidth = window.innerWidth;
        setWidth(newWidth);
        // console.log("updating width");
        };

        window.addEventListener("resize", updateWindowDimensions);

        return () => window.removeEventListener("resize", updateWindowDimensions) 

    }, [width]);

    if(width == 0){
        setWidth(window.innerWidth)
    }

    const elementTitle = [
        '화주 친화적 비용',
        '최첨단 물류센터',
        '호텔식 편의시설',
        '3PL 제공']

    const elementDescription = [
        '물류센터 직접 개발로 화주 친화적인 이용료',
        '물류 전문인력 참여로 고효율의 구조 및 설비',
        '호텔/리조트 개발경험으로 하이퀄리티 편의시설 제공',
        '업계 최효율적 비용의 3PL 서비스 제공']

    const infoImage = [Info1, Info2, Info3, Info4]

    return(
        <div>
            <BackgroundImage>
                {width>900?
                <ContextContainer>
                    {/* <Context style={{marginRight:"8px"}}>우리 공간창조 하이브는 여러분의 </Context> */}
                    <Context style={{fontWeight:700}}>HIVE</Context>
                    <Context>는 최고의 공간을 제공합니다</Context>
                </ContextContainer>:<><div style={{paddingTop:"100px"}}></div></>}
                <div style={{width:"100%", display:"flex", justifyContent:"center"}}>
                <ElementContainer>
                    {elementTitle.map((ele, index) => (
                        <ElementCard>
                            <ElementCircle>
                                {index == 0? <img style={{marginTop:"40px"}} src={infoImage[index]}/>  :
                                <img style={{marginTop:"25px"}} src={infoImage[index]}/>                                                            }
                            </ElementCircle>
                            <ElementTitle>{ele}</ElementTitle>
                            <div style={{display:"flex", justifyContent:"center", width:"100%"}}>
                                <ElementDescription>{elementDescription[index]}</ElementDescription>
                            </div>
                        </ElementCard>
                    ))}
                </ElementContainer>
                </div>
            </BackgroundImage>
        </div>
    )
}

const BackgroundImage = styled.div`
    // display: flex;
    width: 100%;
    min-height: 720px;
    padding-bottom: 100px;
    // padding-left: 10%;
    align-items: center;
    background-color: white;
    // background: linear-gradient(rgba(255,255,255,0.8), rgba(255,255,255,0.8)), url();
    background-size: 100% 200%;
    backdrop-filter:brightness(0%);
`

const Context = styled.div`
    font-size: 30px;
    font-weight: 400;
`

const ContextContainer = styled.div`
    width: 100%;
    height: 50px;
    display: flex;
    flex-flow: wrap;
    justify-content: center;
    // margin-top: -300px;
    margin-bottom: 90px;
    padding-top: 200px;
`

const ElementContainer = styled.div`
    width: 70%;
    display: flex;
    flex-flow: wrap;
    // justify-content: space-between;
    justify-content: center;
`

const ElementCard = styled.div`
    width: 200px;
    align-items: center;
    text-align: center;
    margin-bottom: 30px;
    margin-left: 10px;
    margin-right: 10px;
`

const ElementCircle = styled.div`
    width: 116px;
    height: 116px;
    margin-left: 42px;
    margin-bottom: 30px;
    border-radius: 58px;
    border: 0;
    background-color: white;
    // box-shadow: 10px 10px 10px 10px;
    filter: drop-shadow(0 0px 8px rgba(24, 39, 75, 0.12));
    filter: drop-shadow(0 0px 12px rgba(24, 39, 75, 0.12));
`

const ElementTitle = styled.div`
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 10px;
`

const ElementDescription = styled.div`
    width: 170px;
    font-size: 14px;
    font-weight: 500;
    word-break: keep-all;
    line-height: 23px;
`


export default FirstSection;