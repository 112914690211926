import React, { Component, useEffect, useState, useRef } from 'react'; 
import styled from "styled-components";
import Logo from "../../img/logoWhite.png";

const CodePage = () => {
    const code = "1234"
    const [codeValue, setCodeValue] = useState('');
    const [codeCorrect, setCodeCorrect] = useState(true);

    const enterOnClick = () => {
        if(code == codeValue){
            localStorage.setItem('admin', 'true')
            window.location.href="/admin"
        }else{
            localStorage.setItem('admin', 'false')
            setCodeCorrect(false)
        }
    }

    return (
        <PageContainer>
            <div style={{marginLeft:"10%", marginRight:"10%"}}>
            <TitleContainer>
                <Title>관리자 인증코드</Title>
                <Title style={{marginLeft:"10px", fontWeight:400}}>입력</Title>
            </TitleContainer>
            <Line />
            <ContentContainer style={{marginTop:"80px", marginBottom:"80px"}}>
                <LogoImg src={Logo}/>
            </ContentContainer>
            <ContentContainer>
                <CodeInput placeholder="인증코드를 입력하세요." value={codeValue} onChange={(e)=>(setCodeValue(e.target.value))} />
            </ContentContainer>
            {codeCorrect ? <></> : 
            <ContentContainer style={{marginTop:"10px", marginBottom:"-10px"}}>
                <ErrorLog>잘못된 코드입니다. 다시 입력해주세요.</ErrorLog>
            </ContentContainer>}
            <ContentContainer style={{marginTop:"30px"}}>
                <EnterButton onClick={enterOnClick}>HIVE 관리자 페이지 입장</EnterButton>
            </ContentContainer>
            </div>
        </PageContainer>
    )
}

const PageContainer = styled.div`
    background-color: #29292C;
    width: 100vw;
    height: 100vh;
    // padding: 40px;
    // padding-left: 10%;
    // padding-right: 10%;
`

const TitleContainer = styled.div`
    padding-top: 50px;
    padding-bottom: 10px;
    display: flex;
    color: white;
`

const Title = styled.div`
    font-weight: 800;
    font-size: 25px;
`

const Line = styled.div`
    height: 3px;
    width: 100%;
    background-color: white;
`

const LogoImg = styled.img`
    width: 100px;
`

const ContentContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`

const CodeInput = styled.input`
    width: 300px;
    height: 50px;
    border: 2px white solid;
    background-color: #29292C;
    color: white;
    padding-left: 20px;
    font-size: 25px;
`

const EnterButton = styled.button`
    width: auto;
    border: 0;
    padding-left: 30px;
    padding-right: 30px;
    background-color: gray;
    color: white;
    height: 50px;
    font-size: 21px;
    font-weight: 700;
`

const ErrorLog = styled.div`
    color: red;
    font-size: 15px;
`

export default CodePage;