import React, { Component, useEffect, useState, useRef } from "react";
import MainImage from "../img/main.jpeg";
import styled from "styled-components";
import $ from "jquery";
import { GiHamburgerMenu } from "react-icons/gi";
import Hamburger from "hamburger-react";
import Sidebar from "react-sidebar";
import Logo1 from "../img/logoWhite.png";
import Logo2 from "../img/logoBlack.png";
import Rodal from "rodal";
import "rodal/lib/rodal.css";
import db from "../firestore";

const Header = () => {
  const [width, setWidth] = useState(0);
  const [scrolly, setScrolly] = useState(-1);
  const [preScrolly, setPreScrolly] = useState(0);
  const [isOpen, setOpen] = useState(false);
  const [clickStatus, setClickStatus] = useState(0);
  const [location, setLocation] = useState(0);
  const [visible, setVisible] = useState(false);
  const [contactInfo, setContactInfo] = useState();
  const menu = [
    "#root > div > div.main",
    "#root > div > div.secondsection",
    "#root > div > div.historysection",
    "#root > div > div.firstsection",
    // '#root > div > div.thirdsection',
    "#root > div > div.forthsection",
    "#root > div > div.main",
    // '#root > div > div.fifthsection',
    // '#root > div > div.fifthsection'
  ];

  const menuName = [
    "Home",
    "HIVE Project",
    "History",
    "Service",
    "Location",
    "Contact",
  ];
  const fontColor = ["white", "#1C2DBA", "#1C2DBA", "#1C2DBA", "#1C2DBA"];
  const backColor = ["transparent", "white", "white", "white", "white"];
  const logoColor = [Logo1, Logo2, Logo2, Logo2, Logo2];

  const listener = () => {
    if (scrolly != -1) {
      setPreScrolly(scrolly);
    }
    setScrolly(window.pageYOffset);
    // console.log("here")
    // console.log(window.pageYOffset)
  };

  useEffect(() => {
    var docRef = db.collection("tech").doc("contact");
    docRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          setContactInfo(doc.data());
          // console.log(doc.data()['2020'])
          console.log(contactInfo);
        } else {
          console.log("No such document!");
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });
  }, []);

  useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      setWidth(newWidth);
      // console.log("updating width");
    };

    window.addEventListener("resize", updateWindowDimensions);

    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, [width]);

  useEffect(() => {
    if (clickStatus == 0) {
      var location = 0;
    } else if (clickStatus == 1) {
      var locations = document.querySelector(
        "#root > div > div.firstsection"
      ).offsetTop;
      setLocation(location);
      window.scrollTo({ top: locations, behavior: "smooth" });
    }
  }, clickStatus);

  useEffect(() => {
    window.addEventListener("scroll", listener);
    return () => {
      window.removeEventListener("scroll", listener);
    };
  });

  useEffect(() => {
    let locations = [
      document.querySelector(menu[0]).offsetTop,
      document.querySelector(menu[1]).offsetTop,
      document.querySelector(menu[2]).offsetTop,
      document.querySelector(menu[3]).offsetTop,
      document.querySelector(menu[4]).offsetTop,
      // document.querySelector(menu[5]).offsetTop,
      document.querySelector("#root > div > div.footer").offsetTop,
    ];
    setScrolly(window.scrollY);
    // console.log(window.scrollY)
    // console.log(locations[2])
    // console.log(locations[1])
    locations.map((loca, index) => {
      if (
        (locations[index + 1] > window.scrollY &&
          locations[index] < window.scrollY) ||
        locations[index] == window.scrollY
      ) {
        // console.log(locations[2])
        // console.log(locations[1])
        // console.log("yeah")
        setClickStatus(index);
      }
    });

    // if(locations[1] > window.scrollY){
    //     console.log("hoho")
    //     console.log(locations[2])
    //     console.log(locations[1])
    //     setClickStatus(0)
    // }
  }, [scrolly]);

  // console.log("give height", width);

  if (width == 0) {
    setWidth(window.innerWidth);
  }

  if (scrolly == -1) {
    setScrolly(window.scrollY);
    // console.log("hello")
  }

  return (
    <HeaderContainer style={{ backgroundColor: backColor[clickStatus] }}>
      {document.querySelector("#root > div > div.firstsection") ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            marginRight: "10%",
          }}
        >
          {clickStatus == 0 &&
          document.querySelector("#root > div > div.firstsection").offsetTop <=
            window.scrollY ? (
            // <Logo style={{color:`${fontColor[1]}`}}>공간창조</Logo>:<Logo style={{color:`${fontColor[clickStatus]}`}}>공간창조</Logo>
            <LogoImg src={logoColor[1]} />
          ) : (
            <LogoImg src={logoColor[clickStatus]} />
          )}
          {width > 900 ? (
            <MenuContainer>
              {menu.map((loca, index) => (
                <div>
                  {index == 5 ? (
                    <>
                      <div>
                        {clickStatus == index ? (
                          <Menu
                            style={{
                              color: `${fontColor[1]}`,
                              boxShadow: `inset 0 -3px 0 ${fontColor[1]}`,
                            }}
                          >
                            {menuName[index]}
                          </Menu>
                        ) : (
                          <>
                            <Menu
                              style={{ color: `#AAAAAA` }}
                              onClick={() => {
                                console.log("hi");
                                setVisible(true);
                              }}
                            >
                              {menuName[index]}
                            </Menu>
                            <Rodal
                              width={850}
                              height={242}
                              visible={visible}
                              onClose={() => setVisible(false)}
                            >
                              {contactInfo ? (
                                <ModalContainer style={{ display: "flex" }}>
                                  <InfoBox>
                                    <div>임대문의</div>
                                    <NumInfoBox>
                                      <NumLeft>전화 문의</NumLeft>
                                      <NumRight style={{ border: 0 }}>
                                        <a
                                          style={{ color: "black" }}
                                          href={`tel:${contactInfo.data.rentPhone}`}
                                        >
                                          {contactInfo.data.rentPhone}
                                        </a>
                                      </NumRight>
                                    </NumInfoBox>
                                    <NumInfoBox>
                                      <NumLeft>이메일 문의</NumLeft>
                                      <NumRight
                                        onClick={() => {
                                          navigator.clipboard
                                            .writeText(
                                              `${contactInfo.data.rentEmail}`
                                            )
                                            .then(() => {
                                              alert(
                                                "주소가 클립보드에 복사되었습니다!"
                                              );
                                            })
                                            .catch(() => {
                                              alert("copy error!");
                                            });
                                        }}
                                      >
                                        {contactInfo.data.rentEmail}
                                      </NumRight>
                                    </NumInfoBox>
                                  </InfoBox>
                                  <MiddleLine />
                                  <InfoBox>
                                    <div>3PL 문의</div>
                                    <NumInfoBox>
                                      <NumLeft>전화 문의</NumLeft>
                                      <NumRight style={{ border: 0 }}>
                                        <a
                                          style={{ color: "black" }}
                                          href={`tel:${contactInfo.data.plPhone}`}
                                        >
                                          {contactInfo.data.plPhone}
                                        </a>
                                      </NumRight>
                                    </NumInfoBox>
                                    <NumInfoBox>
                                      <NumLeft>이메일 문의</NumLeft>
                                      <NumRight
                                        onClick={() => {
                                          navigator.clipboard
                                            .writeText(
                                              `${contactInfo.data.plEmail}`
                                            )
                                            .then(() => {
                                              alert(
                                                "주소가 클립보드에 복사되었습니다!"
                                              );
                                            })
                                            .catch(() => {
                                              alert("copy error!");
                                            });
                                        }}
                                      >
                                        {contactInfo.data.plEmail}
                                      </NumRight>
                                    </NumInfoBox>
                                  </InfoBox>
                                </ModalContainer>
                              ) : (
                                <></>
                              )}
                            </Rodal>
                          </>
                        )}
                      </div>
                    </>
                  ) : (
                    <div>
                      {clickStatus == 0 &&
                      document.querySelector("#root > div > div.firstsection")
                        .offsetTop <= window.scrollY ? (
                        <div>
                          {clickStatus == index ? (
                            <Menu
                              style={{
                                color: `${fontColor[1]}`,
                                boxShadow: `inset 0 -3px 0 ${fontColor[1]}`,
                              }}
                            >
                              {menuName[index]}
                            </Menu>
                          ) : (
                            <Menu
                              style={{ color: `#AAAAAA` }}
                              onClick={() => {
                                // setClickStatus(index);
                                window.scrollTo({
                                  top: document.querySelector(loca).offsetTop,
                                  behavior: "smooth",
                                });
                              }}
                            >
                              {menuName[index]}
                            </Menu>
                          )}
                        </div>
                      ) : (
                        <div>
                          {clickStatus == index ? (
                            <Menu
                              style={{
                                color: `${fontColor[clickStatus]}`,
                                boxShadow: `inset 0 -3px 0 ${fontColor[clickStatus]}`,
                              }}
                            >
                              {menuName[index]}
                            </Menu>
                          ) : (
                            <Menu
                              style={{ color: `#AAAAAA` }}
                              onClick={() => {
                                // setClickStatus(index);
                                window.scrollTo({
                                  top: document.querySelector(loca).offsetTop,
                                  behavior: "smooth",
                                });
                              }}
                            >
                              {menuName[index]}
                            </Menu>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              ))}
            </MenuContainer>
          ) : (
            <div>
              {/* <GiHamburgerMenu style={{marginRight:"10%", fontSize:"28px"}}/> */}
              <div style={{ marginRight: "10%" }}>
                {isOpen ? (
                  <Sidebar
                    sidebar={
                      <div>
                        <SidebarContainer>
                          {menu.map((loca, index) => (
                            <div>
                              {index == 5 ? (
                                <>
                                  <SidebarContext
                                    onClick={() => setVisible(true)}
                                  >
                                    {menuName[index]}
                                  </SidebarContext>
                                </>
                              ) : (
                                <>
                                  {clickStatus == index ? (
                                    <SidebarContext>
                                      {menuName[index]}
                                    </SidebarContext>
                                  ) : (
                                    <SidebarContext
                                      onClick={() => {
                                        // setClickStatus(index);
                                        window.scrollTo({
                                          top: document.querySelector(loca)
                                            .offsetTop,
                                          behavior: "smooth",
                                        });
                                      }}
                                    >
                                      {menuName[index]}
                                    </SidebarContext>
                                  )}
                                </>
                              )}
                            </div>
                          ))}
                        </SidebarContainer>
                      </div>
                    }
                    open={isOpen}
                    onSetOpen={setOpen}
                    styles={
                      ({ sidebar: { background: "white" } },
                      { root: { height: "100vh" } })
                    }
                    pullRight={false}
                  ></Sidebar>
                ) : (
                  <></>
                )}
                <Rodal
                  width={350}
                  height={400}
                  visible={visible}
                  onClose={() => setVisible(false)}
                >
                  {contactInfo ? (
                    <ModalContainer style={{}}>
                      <InfoBox style={{ marginLeft: "20px" }}>
                        <div>임대문의</div>
                        <NumInfoBox>
                          <NumLeft>전화 문의</NumLeft>
                          <NumRight style={{ border: 0 }}>
                            <a
                              style={{ color: "black" }}
                              href={`tel:${contactInfo.data.rentPhone}`}
                            >
                              {contactInfo.data.rentPhone}
                            </a>
                          </NumRight>
                        </NumInfoBox>
                        <NumInfoBox>
                          <NumLeft>이메일 문의</NumLeft>
                          <NumRight
                            onClick={() => {
                              navigator.clipboard
                                .writeText(`${contactInfo.data.rentEmail}`)
                                .then(() => {
                                  alert("주소가 클립보드에 복사되었습니다!");
                                })
                                .catch(() => {
                                  alert("copy error!");
                                });
                            }}
                          >
                            {contactInfo.data.rentEmail}
                          </NumRight>
                        </NumInfoBox>
                      </InfoBox>
                      {/* <MiddleLine /> */}
                      <InfoBox style={{ marginLeft: "20px" }}>
                        <div>3PL 문의</div>
                        <NumInfoBox>
                          <NumLeft>전화 문의</NumLeft>
                          <NumRight style={{ border: 0 }}>
                            <a
                              style={{ color: "black" }}
                              href={`tel:${contactInfo.data.plPhone}`}
                            >
                              {contactInfo.data.plPhone}
                            </a>
                          </NumRight>
                        </NumInfoBox>
                        <NumInfoBox>
                          <NumLeft>이메일 문의</NumLeft>
                          <NumRight
                            onClick={() => {
                              navigator.clipboard
                                .writeText(`${contactInfo.data.plEmail}`)
                                .then(() => {
                                  alert("주소가 클립보드에 복사되었습니다!");
                                })
                                .catch(() => {
                                  alert("copy error!");
                                });
                            }}
                          >
                            {contactInfo.data.plEmail}
                          </NumRight>
                        </NumInfoBox>
                      </InfoBox>
                    </ModalContainer>
                  ) : (
                    <></>
                  )}
                </Rodal>
                <div style={{ color: `${fontColor[clickStatus]}` }}>
                  <Hamburger toggled={isOpen} toggle={setOpen} />
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div></div>
      )}
    </HeaderContainer>
  );
};

const HeaderContainer = styled.div`
  width: 100%;
  // max-width: 1500px;
  position: fixed;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  height: 100px;
`;

const ModalContainer = styled.div`
  // display: flex;
  justify-content: space-between;
  width: 100%;
  height: 242px;
  font-size: 20px;
  font-weight: 700;
`;

const InfoBox = styled.div`
  width: 300px;
  text-align: center;
  margin: 50px;
`;

const NumInfoBox = styled.div`
  display: flex;
  width: 100%;
  text-align: left;
  font-size: 16px;
  margin-top: 25px;
`;

const NumLeft = styled.div`
  width: 50%;
  text-align: left;
`;

const NumRight = styled.button`
  width: 70%;
  text-align: left;
  font-weight: 400;
  font-size: 16px;
  border: 1px solid black;
  height: 25px;
  border-radius: 5px;
  background-color: white;
`;

const MiddleLine = styled.div`
  height: 167px;
  width: 1px;
  background-color: #c6c6c6;
  margin-top: 50px;
`;

const Logo = styled.div`
  font-size: 28px;
  font-weight: 700;
  margin-left: 10%;
`;

const LogoImg = styled.img`
  margin-left: 10%;
  width: 82px;
  height: 39px;
`;

const MenuContainer = styled.div`
  font-size: 20px;
  font-weight: 700;
  display: flex;
  margin-right: 10%;
  padding-top: 5px;
`;

const Menu = styled.button`
  margin-left: 16px;
  margin-right: 16px;
  border: 0;
  background-color: transparent;
  font-size: 20px;
  font-weight: 700;
  color: #aaaaaa;
`;

const SidebarContainer = styled.div`
  width: 300px;
  height: 700px;
  color: black;
  background-color: white;
`;

const SidebarContext = styled.button`
  width: 100%;
  height: 70px;
  border: 0;
  background-color: white;
  border-bottom: 1px solid #dddddd;
  text-align: left;
  padding-left: 10%;
  font-size: 20px;
  font-weight: 700;
`;

export default Header;
