import React, { Component, useEffect, useState, useRef } from "react";
import MainImage from "../img/main.jpeg";
import FactoryImange from "../img/factoryPicture.png";
import SpaceImage from "../img/space.png";
import KoreaMap from "../img/koreaMap2.png";
import Point from "../img/point2.png";
import styled from "styled-components";
import PointLeft from "../img/pointleft.png";
import PointRight from "../img/pointright.png";
import db from "../firestore";
import { BsArrowRight } from "react-icons/bs";
import Earth from "../img/earth.png";
import MainMap from "../img/mainmap.png";

const Main = () => {
  const [linkInfo, setLinkInfo] = useState();
  const [mapInfo, setMapInfo] = useState();
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      setWidth(newWidth);
      // console.log("updating width");
    };

    window.addEventListener("resize", updateWindowDimensions);

    return () => window.removeEventListener("resize", updateWindowDimensions) 

  }, [width]);

  if(width == 0){
    setWidth(window.innerWidth)
}

  useEffect(() => {
    var docRef = db.collection("tech").doc("link");
    var docRef2 = db.collection("tech").doc("map");
    console.log("들어오긴함?");
    docRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          setLinkInfo(doc.data());
          // console.log(doc.data()['2020'])
          console.log(linkInfo);
        } else {
          console.log("No such document!");
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });

    docRef2.get().then((doc) => {
        if (doc.exists) {
            setMapInfo(doc.data());
        } else {
            console.log("No such document!");
        }
    }).catch((error) => {
        console.log("Error getting document:", error);
    })
  }, []);

  return (
    <div>
        {width>=900?
      <BackgroundImage style={{display:"flex"}}>
        {/* <div style={{display:"flex"}}> */}
        <div style={{ marginLeft: "10%", marginRight: "10%"}}>
            <div style={{ display:"flex"}}>
                <MainContext>HIVE,</MainContext>
            <   SecondContext>Creation of Space</SecondContext>
            </div>
          <ul style={{ color: "rgba(255, 255, 255, 0.6)", lineHeight: "24px" }}>
            {/* <MainList>Logistics Center</MainList> */}
            <li>Logistics Center</li>
            <li>APT</li>
            <li>Hotel & Resort</li>
          </ul>
          <LinkContainer style={{position:"relative"}}>
            <img style={{position:"absolute", left:"250px", top:"10px"}} src={Earth}/>
            <div
              style={{
                marginTop: "25px",
                marginLeft: "20px",
                fontSize: "20px",
                fontWeight: 600,
                marginBottom: "25px",
              }}
            >
              ISSUE & PR
            </div>
            {linkInfo ? (
              <>
                {linkInfo.data.map((value, index) => (
                  <LinkLine onClick={()=>(window.open(value.link))}>
                    <div style={{textDecoration: 'underline'}}>{value.title}</div>
                    <BsArrowRight
                      style={{
                        fontSize: "20px",
                        fontWeight: 600,
                        color: "rgba(255, 255, 255, 0.5)",
                      }}
                    />
                  </LinkLine>
                ))}
              </>
            ) : (
              <></>
            )}
          </LinkContainer>
        </div>
        <MapContainer style={{ left: "100px", top: "30px" }}>
          <div style={{ width: "700px" }}>
            <img style={{ width: "700px" }} src={KoreaMap} />
          </div>
          {mapInfo?<>
            {mapInfo.data.map((pos, index) => (
                <>
                    <ItemTitle style={{ top: `${pos.title_top}px`, left: `${pos.title_left}px` }}>
                        {pos.title}
                    </ItemTitle>
                    <div
                        style={{
                        position: "absolute",
                        top: `${pos.line_top}px`,
                        left: `${pos.line_left}px`,
                        width: "60px",
                        }}
                    >
                        {pos.left?<img src={PointLeft} />:<img src={PointRight} />}
                    </div>
                    <div
                        style={{
                        position: "absolute",
                        top: `${pos.point_top}px`,
                        left: `${pos.point_left}px`,
                        width: "60px",
                        }}
                    >
                        <img src={Point} />
                    </div>
                </>
            ))}
          </>:<></>}
        </MapContainer>
        {/* </div> */}
      </BackgroundImage>:
      <BackgroundImage>
        <div style={{ marginLeft: "10%", marginRight: "10%", paddingTop:"200px"}}>
        <div style={{ display:"flex"}}>
            <MainContext style={{fontSize:"30px"}}>HIVE,</MainContext>
        <   SecondContext style={{fontSize:"25px"}}>Creation of Space</SecondContext>
        </div>
      <ul style={{ color: "rgba(255, 255, 255, 0.6)", lineHeight: "24px" }}>
        {/* <MainList>Logistics Center</MainList> */}
        <li>Logistics Center</li>
        <li>APT</li>
        <li>Hotel & Resort</li>
      </ul>
      {/* <img  style={{width:"100%"}} src={MainMap}/> */}
      <LinkContainer style={{position:"relative", width:"300px"}}>
        <img style={{position:"absolute", left:"170px", top:"10px"}} src={Earth}/>
        <div
          style={{
            marginTop: "25px",
            marginLeft: "20px",
            fontSize: "20px",
            fontWeight: 600,
            marginBottom: "25px",
          }}
        >
          ISSUE & PR
        </div>
        {linkInfo ? (
          <>
            {linkInfo.data.map((value, index) => (
              <LinkLine onClick={()=>(window.location.href=value.link)}>
                <div style={{textDecoration: 'underline'}}>{value.title}</div>
                <BsArrowRight
                  style={{
                    fontSize: "20px",
                    fontWeight: 600,
                    color: "rgba(255, 255, 255, 0.5)",
                  }}
                />
              </LinkLine>
            ))}
          </>
        ) : (
          <></>
        )}
      </LinkContainer>
    </div>
    
    </BackgroundImage>}
    </div>
  );
};

const BackgroundImage = styled.div`
//   display: flex;
  width: 100%;
  height: 720px;
  // padding-left: 10%;
  align-items: center;

  // background-image: url(${MainImage});
  background-image: url(${SpaceImage});
  background-size: cover;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(${SpaceImage});
  background-size: cover;
  backdrop-filter: brightness(0%);
`;

const MapContainer = styled.div`
  position: relative;
`;

const ItemTitle = styled.div`
  position: absolute;
  color: white;
  font-size: 13px;
  font-weight: 500;
`;

const LinkLine = styled.button`
  display: flex;
  justify-content: space-between;
  font-size: 17px;
  color: white;
//   margin-left: 20px;
  margin-bottom: 10px;
//   margin-right: 20px;
    padding-left: 20px;
    padding-right: 20px;
  width: 100%;
  background-color: transparent;
  border: 0;
`;

const ScrollSticker = styled.div``;

const ImageContainer = styled.img`
  left: 30px;
`;

const MainContext = styled.div`
  font-size: 38px;
  font-weight: 700;
  color: white;
  margin-bottom: 5px;
`;

const SecondContext = styled.div`
  font-size: 32px;
  font-weight: 400;
  color: white;
  padding-top: 5px;
  margin-left: 10px;
`;

const MainList = styled.li`
  // color: rgba(225, 255, 225, 0.5);
  // line-height: 24px;

  &:before {
    color: white;
  }
`;

const LinkContainer = styled.div`
  width: 371px;
  height: 189px;
  border-radius: 20px;
  border: 2px solid #1850bc;
  // background-color: rgba(0, 147, 205, 0.24);
  background-color: rgba(24, 80, 188, 0.24);
  color: white;
  margin-top: 60px;
`;

export default Main;
